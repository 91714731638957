import React, { Fragment } from 'react';
import styled from 'styled-components';
import { chunk } from 'lodash-es';
import QuoteOption, { LoadingQuoteOption } from './QuoteOptions';
import { QuoteOptionViewModel } from './QuoteOptionViewModel';
import { Spacer } from '../../Components/SimpleComponents';

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const LoadingQuoteOptions = () => (
  <>
    <Row>
      <LoadingQuoteOption />
      <LoadingQuoteOption />
    </Row>
    <Spacer y={5} />
    <Row>
      <LoadingQuoteOption />
      <LoadingQuoteOption />
    </Row>
  </>
);

type Props = {
  isLoading: boolean;
  quotes: QuoteOptionViewModel[];
  onTermClicked: (term: number) => void;
};
export default function QuoteOptionsContainer({
  quotes = [],
  onTermClicked,
  isLoading,
}: Props): React.ReactElement {
  const rows = chunk<QuoteOptionViewModel>(quotes, 2);

  return (
    <Container>
      {isLoading ? (
        <LoadingQuoteOptions />
      ) : (
        rows.map((quoteRow, i) => (
          <Fragment key={i}>
            <Row key={i}>
              {quoteRow.map((q) => (
                <QuoteOption
                  key={q.premium}
                  term={q.term}
                  premium={q.premium}
                  coverage={q.coverage}
                  onClick={() => onTermClicked(q.term)}
                />
              ))}
            </Row>
            <Spacer y={5} />
          </Fragment>
        ))
      )}
    </Container>
  );
}
