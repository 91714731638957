const MAX_ZIP_NBRS = 5;
export const formatZip = (value: string) => {
  if (!value) {
    return '';
  }

  const prunedZip = value.replace(/[^0-9]/g, '');
  const formattedZip = prunedZip.substring(0, MAX_ZIP_NBRS);

  return formattedZip;
};
