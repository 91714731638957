import { DisplayHint } from '../models/application';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const neverReached = (_never: never) => {};

export const formatText = (value: string, displayHint: DisplayHint): string => {
  switch (displayHint) {
    case 'ssn':
      return formatSSN(value);
    case 'phone':
      return formatPhone(value);
    case 'money':
      return formatMoney(value);
    case 'checkbox':
      return value;
    case 'email':
      return value;
    default:
      neverReached(displayHint);
      break;
  }
};

//TODO: Move formatters out to separate file?

export const toTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const moneyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0, // Unit tests throw out of range error without both min and max defined
  maximumFractionDigits: 0,
});

export const formatMoney = (value: string): string => {
  if (!value) {
    return '';
  }

  return moneyFormatter.format(parseInt(value, 10));
};

const MAX_SSN_NBRS = 9;
export const formatSSN = (value: string): string => {
  if (!value) {
    return '';
  }
  let prunedSSNbr = value.replace(/[^0-9]/g, '');

  if (prunedSSNbr.length > MAX_SSN_NBRS) {
    prunedSSNbr = prunedSSNbr.substring(0, MAX_SSN_NBRS);
  }

  if (!prunedSSNbr) {
    return '';
  }

  let formattedSSNbr = `${prunedSSNbr.substring(0, 3)}`;

  if (prunedSSNbr.length >= 4) {
    formattedSSNbr += `-${prunedSSNbr.substring(3, 5)}`;
  }

  if (prunedSSNbr.length >= 6) {
    formattedSSNbr += `-${prunedSSNbr.substring(5)}`;
  }

  return formattedSSNbr;
};

const MAX_PHONE_NBRS = 10;
export const formatPhone = (value: string): string => {
  if (!value) {
    return '';
  }

  let prunedPhoneNbr = value.replace(/[^0-9]/g, '');

  if (prunedPhoneNbr.length > MAX_PHONE_NBRS) {
    prunedPhoneNbr = prunedPhoneNbr.substring(0, MAX_PHONE_NBRS);
  }

  if (!prunedPhoneNbr) {
    return '';
  }

  let formattedPhoneNbr = `(${prunedPhoneNbr.substring(0, 3)}`;

  if (prunedPhoneNbr.length >= 4) {
    formattedPhoneNbr += `) ${prunedPhoneNbr.substring(3, 6)}`;
  }

  if (prunedPhoneNbr.length >= 7) {
    formattedPhoneNbr += `-${prunedPhoneNbr.substring(6)}`;
  }

  return formattedPhoneNbr;
};
