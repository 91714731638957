import React from 'react';
import styled from 'styled-components/native';
import { ViewStyle } from 'react-native';
import { WaffleOrange } from '../../Constants/Style';

const Container = styled.View`
  height: 7px;
  width: 100%;
  background-color: rgb(252, 230, 194);
  position: fixed;
`;

const Progress = styled.View`
  height: 7px;
  width: 35px;
  background-color: ${WaffleOrange};
  justify-content: center;
  align-items: flex-end;
`;

type ProgressBarProps = {
  percent: number;
  style?: ViewStyle;
  containerStyle?: ViewStyle;
};

const LifeProgressBar = ({ percent, style }: ProgressBarProps) => {
  return (
    <Container>
      <Progress style={[{ width: `${percent}%` }, style]} />
    </Container>
  );
};

export default LifeProgressBar;
