import { Quote } from '../../LifeQuoteScreen/models/application';
import { QuoteOptionViewModel } from '../QuoteOptionViewModel';

export const mapQuoteToQuoteViewModel = (
  quote?: Quote
): QuoteOptionViewModel => {
  let premium = '';
  let term = 0;
  let coverage = '';

  if (quote) {
    premium = quote.monthly_premium.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    term = quote.term;

    coverage = quote.coverage.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  return {
    premium,
    term,
    coverage,
  };
};
