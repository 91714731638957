import { useApolloClient } from '@apollo/client';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import * as yup from 'yup';
import style from '../Constants/Style';
import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import UserNameInput from '../Components/UserNameInput';
import PasswordInput from '../Components/PasswordInput';
import { CenterLinkText, Spacer } from '../Components/SimpleComponents';
import { useFocusEffect } from '@react-navigation/native';

import WaffleText from '../Components/WaffleText';
import { VerticalCenter } from '../Components/SimpleLayouts';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import { ErrorDisplay } from '../Components/ErrorDisplay';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import { NormalButtonWidth } from '../Helper/DeviceHelper';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ForgotPasswordScreenName,
  RootStackParamList,
} from '../../screen-config';
import { useAuthContext } from './AuthProvider';
import { Login } from '../Helper/AuthHelper';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';

interface State {
  email?: string;
  password?: string;
}

const schema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().min(5).required(),
});

type Props = StackScreenProps<RootStackParamList, 'Login'>;

const LoginScreen = ({ navigation, route }: Props) => {
  const initialMessage = route.params?.initialMessage ?? '';
  const initialEmail = route.params?.initialEmail ?? '';
  const [state, setState] = useState<State>({});
  const [error, setError] = useState('');
  const client = useApolloClient();
  const { setIsSignedIn } = useAuthContext();
  const userTracking = useUserTracking();

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
    });
  }, [navigation]);

  // todo : get rid of bounce!
  const onChangeText = (key: keyof State, text: string) =>
    setState({ ...state, [key]: text });

  useFocusEffect(useCallback(() => setError(''), [])); // Reset error when we go back to this screen.
  useFocusEffect(
    useCallback(() => onChangeText('email', initialEmail), [initialEmail])
  );

  // Logger(`LoginScreen: initialEmail=${initialEmail} state=${JSON.stringify(state)}`);

  async function login() {
    try {
      const creds = await Login(
        navigation,
        client,
        state.email,
        state.password,
        setIsSignedIn
      );

      userTracking?.linkAnonymousUserToCreatedUser(creds?.user?.id);
    } catch (e) {
      setError(e);
    }
  }

  return (
    <KeyboardViewMaybe>
      <WaffleCreateHeader title={'Login'} />

      <VerticalCenter style={[style.fullWidth, style.fullHeight]}>
        {!!initialMessage && <WaffleText>{initialMessage}</WaffleText>}

        <ErrorDisplay error={error} />

        <UserNameInput
          label={'Email'}
          onChangeText={(text: string) => onChangeText('email', text)}
          containerStyle={{ width: 300 }}
          value={state.email}
        />

        <Spacer y={2} />

        <PasswordInput
          label={'Password'}
          onChangeText={(text: string) => onChangeText('password', text)}
          otherProps={{
            autoCompleteType: 'password',
            textContentType: 'password',
          }}
          containerStyle={{ width: 300 }}
        />

        <Spacer y={1} />

        <CenterLinkText
          onPress={() => navigation.navigate(ForgotPasswordScreenName)}>
          Forgot your password?
        </CenterLinkText>

        <Spacer y={2} />

        <WaffleButtonAsync
          disabled={!schema.isValidSync(state)}
          onPress={login}
          style={{ width: NormalButtonWidth() }}
          name={'Login'}
          pressedName={'Logging In...'}
        />
      </VerticalCenter>
    </KeyboardViewMaybe>
  );
};

export default LoginScreen;
