/*
 * Ladder Life Insurance API
 *
 * External documentation:
 * - https://ladderlife.github.io/api/#ladder-platform-overview
 */

import axios from 'axios';
import GetLadderConfig from './../utils/ladder-config';
import { cachedTokenReactiveVar } from '../../../GraphQL/Waffle/ApolloClientBuilder';
import { CreateQuoteValidationError } from './CreateQuoteValidationError';

// Document conflicts with an existing type, so used LifeDocument
export type LifeDocument = {
  id: string;
  name: string;
  type: string;
  link: string;
};
export type TobaccoUse =
  | 'never'
  | 'within_last_12_months'
  | '12_to_23_months'
  | '24_to_35_months'
  | '36_months_or_greater';

export type Gender = 'male' | 'female' | 'other';

type QuoteRequest = {
  coverage: number;
  date_of_birth: string; // yyyy-mm-dd
  email: string;
  external_id: string;
  family_history: boolean; // Biological parent or sibling diagnosed with cancer, diabetes, or heart disease before the age of 60.
  first_name?: string;
  height_inches: number; // height in inches
  household_income: number;
  last_name?: string;
  sex: Gender;
  term: number;
  tobacco_use: TobaccoUse;
  weight_pounds: number;
  zipcode: string;
};

type QuoteRequestUpdate = {
  coverage?: number;
  date_of_birth?: string; // yyyy-mm-dd
  external_id?: string;
  family_history?: boolean; // Biological parent or sibling diagnosed with cancer, diabetes, or heart disease before the age of 60.
  first_name?: string;
  height_inches?: number; // height in inches
  household_income?: number;
  last_name?: string;
  sex?: Gender;
  term?: number;
  tobacco_use?: TobaccoUse;
  weight_pounds?: number;
  zipcode?: string;
};

type QuoteStatus = {
  valid: boolean;
  complete: boolean;
  eligible: boolean;
};

type Quote = {
  coverage: number;
  monthly_premium: number;
  daily_price: number;
  term: number;
};

type QuoteResponse = {
  id: string;
  quote_status: QuoteStatus;
  quotes: Array<Quote>;
};

type QuestionType =
  | 'boolean'
  | 'text'
  | 'integer'
  | 'date'
  | 'singleselect'
  | 'multiselect'
  | 'group'
  | 'hosted';

type DisplayHint = 'email' | 'money' | 'phone' | 'ssn' | 'checkbox';

type Question = {
  id: string;
  type: QuestionType;
  uses_autocomplete_endpoint: boolean;
  display_hint?: DisplayHint;
  caption: string;
  subtext?: string;
  subcaption?: string;
  subquestions?: Array<Question>;
  compliance_documents?: Array<LifeDocument>; // fix
  validation?: object; // fix
  breadcrumbs?: Array<string>;
  answer?: AnswerValue; // fix
  answer_valid: boolean;
  answer_validation_message?: string;
  answer_options_endpoint?: string;
  answer_options?: Array<AnswerOption>; // fix
};

type AnswerOption = {
  value: AnswerValueScalar;
  text: string;
  details?: string;
};

type ApplicationRequest = {
  user?: string;
  quoter?: string; // must specify quoter or user. make this union
  email: string; // required?
  state?: string;
};

type ApplicationResponse = {
  id: string;
  hash: string;
  user: string;
  offer?: string;
  policy?: string;
  created_at: number;
  signed_at?: number;
  closed_at?: number;
  duration_years?: number;
  eligible_for_api: boolean;
  face_amount?: number;
  ineligible: boolean;
  ineligibility_reason?: string;
  identity_status?:
    | 'verified'
    | 'invalid'
    | 'pending'
    | 'duplicate'
    | 'different';
  estimated_progress?: number;
  next_incomplete_question?: Question;
  answered_questions: Array<Question>;
};

type ApplicationDecisionResponse = {
  status: 'pending' | 'completed';
  decision?: 'term' | 'manual_underwriting' | 'declined';
  offer?: string;
};

type Offer = {
  id: string;
  application: string;
  policy?: string;
  status: 'active' | 'accepted' | 'refused' | 'expired' | 'canceled';
  offered_at: number;
  expires_at: number;
  face_amount: number;
  duration_years: number;
  monthly_premium_cents: number;
};

type User = {
  id: string;
  applications: Array<string>;
  policies: Array<string>;
  payment_status: string;
};

type AnswerValueScalar = boolean | string | number;

// Record is for 'group'.
// Array is for 'multiselect'
// AnswerValueScalar is for singleselect and other scalar types
type AnswerValue =
  | AnswerValueScalar
  | Array<AnswerValueScalar>
  | Record<string, AnswerValueScalar | Array<AnswerValueScalar>>;

type Answer = {
  id: string;
  value: AnswerValue;
};

type LifeRedirectLink = {
  link: string;
  expiresAt: number;
};

async function getHeaders() {
  const token = cachedTokenReactiveVar();

  return {
    Authorization: `Bearer ${token}`,
  };
}

async function createQuote(quote: QuoteRequest): Promise<QuoteResponse> {
  const VALIDATION_ERROR_LOOKUP = {
    coverage: 'Coverage',
    date_of_birth: 'Date of Birth',
    email: 'Email',
    family_history: 'Family History',
    first_name: 'First Name',
    height_inches: 'Height',
    household_income: 'Annual Income',
    last_name: 'Last Name',
    sex: 'Gender',
    term: 'Years Of Coverage',
    tobacco_use: 'Tobacco Use',
    weight_pounds: 'Weight',
    zipcode: 'Zipcode',
  };
  try {
    const { data } = await axios.post<QuoteResponse>(
      `${GetLadderConfig().API_URL}/v1/quoter`,
      quote,
      {
        headers: await getHeaders(),
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );

      if (status === 400 && data?.reason) {
        const invalidInputFields = Object.keys(data.reason)
          .map((k) => VALIDATION_ERROR_LOOKUP[k])
          .filter((k) => k)
          .join(',');
        throw new CreateQuoteValidationError(invalidInputFields);
      }
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function getQuote(quoteId: string): Promise<QuoteResponse> {
  try {
    const { data } = await axios.get<QuoteResponse>(
      `${GetLadderConfig().API_URL}/v1/quoter/${quoteId}`,
      {
        headers: await getHeaders(),
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function updateQuote(
  quoterID: string,
  quoteUpdate: QuoteRequestUpdate
): Promise<QuoteResponse> {
  try {
    const { data } = await axios.put<QuoteResponse>(
      `${GetLadderConfig().API_URL}/v1/quoter/${quoterID}`,
      quoteUpdate,
      {
        headers: await getHeaders(),
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function createApplication(
  request: ApplicationRequest
): Promise<ApplicationResponse> {
  try {
    // todo : transition!
    const { data } = await axios.post<ApplicationResponse>(
      `${GetLadderConfig().API_URL}/v2/applications`,
      request,
      { headers: await getHeaders() }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function getApplication(
  applicationId: string
): Promise<ApplicationResponse> {
  try {
    const response = await axios.get<ApplicationResponse>(
      `${GetLadderConfig().API_URL}/v2/applications/${applicationId}`,
      { headers: await getHeaders() }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function signApplication(
  applicationID: string,
  hash: string
): Promise<ApplicationResponse> {
  try {
    const { data } = await axios.post<ApplicationResponse>(
      `${GetLadderConfig().API_URL}/v2/applications/${applicationID}/sign`,
      { hash },
      { headers: await getHeaders() }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function getApplicationDecision(
  applicationID: string
): Promise<ApplicationDecisionResponse> {
  try {
    const response = await axios.get<ApplicationDecisionResponse>(
      `${GetLadderConfig().API_URL}/v2/applications/${applicationID}/decision`,
      { headers: await getHeaders() }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function getOffer(offerID: string): Promise<Offer> {
  try {
    const { data } = await axios.get<Offer>(
      `${GetLadderConfig().API_URL}/v2/offers/${offerID}`,
      { headers: await getHeaders() }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function acceptOffer(offerID: string): Promise<Offer> {
  try {
    const { data } = await axios.post<Offer>(
      `${GetLadderConfig().API_URL}/v2/offers/${offerID}/accept`,
      {},
      { headers: await getHeaders() }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function getUser(userID: string): Promise<User> {
  try {
    const { data } = await axios.get<User>(
      `${GetLadderConfig().API_URL}/v2/users/${userID}`,
      { headers: await getHeaders() }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function answerQuestion(
  applicationId: string,
  answer: Answer
): Promise<ApplicationResponse> {
  try {
    const { data } = await axios.post<ApplicationResponse>(
      `${GetLadderConfig().API_URL}/v2/applications/${applicationId}/answer`,
      answer,
      { headers: await getHeaders() }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function getUserToken(userId: string): Promise<string> {
  try {
    const {
      data: { token },
    } = await axios.post(
      `${GetLadderConfig().API_URL}/v2/users/${userId}/token`,
      {},
      { headers: await getHeaders() }
    );

    return token;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
      console.log(data);
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function autocomplete(
  url: string,
  query: AnswerValueScalar
): Promise<AnswerOption[]> {
  try {
    const { data } = await axios.post(
      `${GetLadderConfig().API_URL}/autocomplete`,
      { url, query },
      { headers: await getHeaders() }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.log('axios error:', error.message);
      const { status, statusText, data } = error.response;
      console.log(
        `status=${status} statusText='${statusText}' data='${JSON.stringify(
          data
        )}'`
      );
    } else {
      console.log('other error:', error);
    }
    throw error;
  }
}

async function getLifeRedirectLink(userId: string): Promise<LifeRedirectLink> {
  // https://www.ladderlife.com/api/v2/users/:id/redirect
  const url = `${GetLadderConfig().API_URL}/v2/users/${userId}/redirect`;
  const { data } = await axios.post(url, {}, { headers: await getHeaders() });

  return data;
}

export {
  Answer,
  AnswerOption,
  AnswerValue,
  AnswerValueScalar,
  ApplicationDecisionResponse,
  ApplicationRequest,
  ApplicationResponse,
  DisplayHint,
  Offer,
  Question,
  QuestionType,
  Quote,
  QuoteRequest,
  QuoteResponse,
  User,
  acceptOffer,
  answerQuestion,
  autocomplete,
  createApplication,
  createQuote,
  getApplication,
  getApplicationDecision,
  getQuote,
  getLifeRedirectLink,
  getOffer,
  signApplication,
  updateQuote,
  getUserToken,
  getUser,
};
