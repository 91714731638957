import {
  AnswerValue,
  AnswerValueScalar,
  Question,
} from '../models/application';
import { Default } from './question-default-empty-values.constant';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const neverReached = (_never: never) => {};

export const isNullOrUndefinedAnswer = (value: AnswerValue): boolean =>
  value === undefined || value === null;

export const isNullOrUndefined = (value: unknown): boolean =>
  value === undefined || value === null;

export const getDefaultAnswerValue = (question: Question): AnswerValue => {
  switch (question.type) {
    case 'boolean':
      return isNullOrUndefinedAnswer(question.answer)
        ? Default.Boolean
        : question.answer;
    case 'integer':
      return isNullOrUndefinedAnswer(question.answer)
        ? Default.Integer
        : question.answer;
    case 'text':
      return isNullOrUndefinedAnswer(question.answer)
        ? Default.Text
        : question.answer;
    case 'date':
      return isNullOrUndefinedAnswer(question.answer)
        ? Default.Date
        : question.answer;
    case 'singleselect':
      return isNullOrUndefinedAnswer(question.answer)
        ? Default.Singleselect
        : question.answer;
    case 'multiselect':
      return isNullOrUndefinedAnswer(question.answer)
        ? Default.Multiselect
        : question.answer;
    case 'group':
      throw new Error('Not supported.');
    case 'hosted':
      return Default.Hosted;
    default:
      neverReached(question.type);
  }
};
/**
 * Converts ladder life AnswerValueScalar array to compatible react-select options
 * @param answerOptions ladder life answer options array
 * @returns react-select compatible options array
 */
export const mappedAnswerValueOptions = (answerOptions: AnswerValueScalar[]) =>
  answerOptions.map((answerValue: AnswerValueScalar) => ({
    text: String(answerValue),
    value: answerValue,
  }));
