import React, { useState } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { Question, AnswerValue } from './models/application';
import QuestionComponentFactoryV2 from './QuestionComponentFactoryV2';
import { ArrowButton } from './ArrowButton';
import { Spacer } from '../../Components/SimpleComponents';
import {
  getDefaultAnswerValue,
  isNullOrUndefined,
} from './utils/question-helpers';
import { QuestionExternalDependencies } from './utils/ladder-life-coverage.constants';
import WaffleText from '../../Components/WaffleText';
import ContentLoader from 'react-content-loader';
import { ErrorContainer } from '../../Components/ErrorContainer';
import {
  appErrMsg,
  errMsg,
} from '../LifeQuoteQuestionsScreen/utils/api-error-message';
import {
  ApplicationError,
  validationErr400,
} from '../LifeQuoteQuestionsScreen/api-error.constants';
import Sentry from '../../Sentry';
type AppFormError = {
  msg: string;
  err?: ApplicationError;
};

type FormState = {
  data: object;
  isLoading: boolean;
  appFormErr: AppFormError;
};

type Props = {
  onSubmit: (questionID: string, answer: AnswerValue) => Promise<void>;
  onPrevious: (questionID: string, answer: AnswerValue) => Promise<void>;
  formQuestion: Question;
  initialFormData: object;
  formStyle?: StyleProp<ViewStyle>;
  externalDependencies?: QuestionExternalDependencies;
};

const isGroupTypeErr = (
  question: Question,
  appErr: ApplicationError
): boolean => question?.type === 'group' && !isNullOrUndefined(appErr?.details);

export default function QuoteFormDynamic(props: Props): React.ReactElement {
  const { formQuestion, initialFormData, formStyle, externalDependencies } =
    props;
  const [formState, setFormState] = useState<FormState>({
    data: { ...initialFormData },
    isLoading: false,
    appFormErr: null,
  });

  const onValueChange = (id: string, value: AnswerValue): void => {
    if (formQuestion.type === 'group') {
      const parentID = formQuestion.id;
      const parentData = formState.data[parentID];
      setFormState({
        ...formState,
        data: {
          ...formState.data,
          [parentID]: { ...parentData, [id]: value },
        },
      });
    } else {
      setFormState({
        ...formState,
        data: { ...formState.data, [id]: value },
      });
    }
  };

  const onSubmit = async (): Promise<void> => {
    try {
      setFormState({
        ...formState,
        appFormErr: null,
        isLoading: true,
      });
      const questionID = formQuestion.id;
      await props.onSubmit(questionID, formState.data[questionID]);
      setFormState({
        ...formState,
        isLoading: false,
        appFormErr: null,
      });
    } catch (e) {
      console.error(e);

      if (e.response?.status !== validationErr400) {
        // Mainly care about capturing non-validation errors
        Sentry.captureException(e);
      }

      setFormState({
        ...formState,
        appFormErr: {
          msg: e.response?.data
            ? appErrMsg(e.response.data)
            : errMsg(e.message),
          err: e.response?.data ? e.response.data : null,
        },
        isLoading: false,
      });
    }
  };

  const onPrevious = async (): Promise<void> => {
    try {
      setFormState({
        ...formState,
        appFormErr: null,
        isLoading: true,
      });
      const questionID = formQuestion.id;
      await props.onPrevious(questionID, formState.data[questionID]);
      setFormState({
        ...formState,
        isLoading: false,
        appFormErr: null,
      });
    } catch (e) {
      console.error(e);

      if (e.response?.status !== validationErr400) {
        // Mainly care about capturing non-validation errors
        Sentry.captureException(e);
      }

      setFormState({
        ...formState,
        appFormErr: {
          msg: e.response?.data
            ? appErrMsg(e.response.data)
            : errMsg(e.message),
          err: e.response?.data ? e.response.data : null,
        },
      });
    }
  };

  const type = formQuestion.type;
  const defaultFormData = formState.data;
  switch (type) {
    case 'group': {
      if (
        !Object.prototype.hasOwnProperty.call(defaultFormData, formQuestion.id)
      ) {
        defaultFormData[formQuestion.id] = {};
      }

      const parentData = defaultFormData[formQuestion.id];

      formQuestion.subquestions.forEach((subQ: Question) => {
        if (!Object.prototype.hasOwnProperty.call(parentData, subQ.id)) {
          parentData[subQ.id] = getDefaultAnswerValue(subQ);
        }
      });
      break;
    }
    default:
      if (
        !Object.prototype.hasOwnProperty.call(defaultFormData, formQuestion.id)
      ) {
        defaultFormData[formQuestion.id] = getDefaultAnswerValue(formQuestion);
      }
  }

  return (
    <View style={formStyle ? formStyle : styles.formStyle}>
      {!isGroupTypeErr(formQuestion, formState.appFormErr?.err) &&
      formState.appFormErr?.msg ? (
        <ErrorContainer>
          <WaffleText>{formState.appFormErr.msg}</WaffleText>
        </ErrorContainer>
      ) : null}
      {formState.isLoading ? (
        <ContentLoader
          speed={2}
          width="100%"
          height={160}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb">
          <rect x="0" y="5" rx="0" ry="0" width="100%" height="45" />
          <rect x="100" y="90" rx="0" ry="0" width="50%" height="25" />
          <rect x="100" y="130" rx="0" ry="0" width="50%" height="25" />
        </ContentLoader>
      ) : (
        <View style={styles.questionStyle}>
          {formQuestion.type === 'group' && (
            <QuestionComponentFactoryV2
              onValueChange={onValueChange}
              formQuestion={formQuestion}
              groupFormData={formState.data[formQuestion.id]}
              externalDependencies={externalDependencies}
              value={null}
              errors={formState.appFormErr?.err?.details}
            />
          )}

          {formQuestion.type !== 'group' && (
            <QuestionComponentFactoryV2
              onValueChange={onValueChange}
              formQuestion={formQuestion}
              externalDependencies={externalDependencies}
              value={formState.data[formQuestion.id]}
            />
          )}
        </View>
      )}

      <Spacer y={2} />
      <View style={styles.buttonsStyle}>
        <ArrowButton
          direction="left"
          onPress={onPrevious}
          disabled={formState.isLoading}
        />
        <Spacer x={4} />
        <ArrowButton onPress={onSubmit} disabled={formState.isLoading} />
      </View>
      <Spacer y={4} />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonsStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    marginTop: 'auto',
    paddingTop: 20,
    height: 'auto',
  },
  questionStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    //flexWrap: 'wrap', // Overflows on the CSS grid in chrome for some reason...
    height: 'auto',
    width: 'auto',
    zIndex: 1,
    /**
     * Notes:
     * z-index increase allows elements like react-select that can't supersede their parent's z-index to not get overlapped
     * at the default 0 z-index value
     * */
  },
  formStyle: {
    width: 637,
    minHeight: 366,
    height: 'auto',
    marginTop: 20,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 28px -9px rgba(0, 0, 0, 0.25)',
    borderRadius: 21,
    padding: 15,
    flexDirection: 'column',
  },
  updatingStyle: {
    left: '50%',
    right: '50%',
    top: '50%',
    bottom: '50%',
    //transform: [{ translateX: '50%', translateY: '-50%' }], // Not supported at the moment?
    position: 'absolute', // couldn't use Display: 'grid' to overlap content so went with absolute
  },
});

// See more on 'z-index-isnt-working' on https://coder-coder.com/z-index-isnt-working/
