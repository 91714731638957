import { StackScreenProps } from '@react-navigation/stack';
import {
  LifeApplicationDeclined,
  LifeQuoteQuestionsScreenName,
  LifeQuoteScreenName,
  RootStackParamList,
} from '../../../screen-config';
import React, { useCallback, useState } from 'react';
import {
  createQuote,
  QuoteRequest,
  updateQuote,
} from '../LifeQuoteScreen/models/application';
import { useLadderLogoNavigationHeader } from '../LifeApplicationReviewScreen/useLadderNavigationHeader';
import styled from 'styled-components';
import { Spacer } from '../../Components/SimpleComponents';
import LifeDisclosure from '../../Components/LifeDisclosure';
import { useFocusEffect } from '@react-navigation/native';
import { getLifePolicyMetaDataAsync } from '../../../lib/life';
import { useApolloClient } from '@apollo/client';
import { useAuthContext } from '../AuthProvider';
import { formatZip } from './utils/formatters';
import LifeQuoteQuestionsPart1View from './LifeQuoteQuestionsPart1View';
import { LifeQuestionAnswersPart1 } from './LifeQuestionAnswersPart1';
import LifeQuoteQuestionsPart2View from './LifeQuoteQuestionsPart2View';
import { LifeQuestionAnswersPart2 } from './LifeQuestionAnswersPart2';
import LifeQuoteQuestionsPart3View from './LifeQuoteQuestionsPart3View';
import { LifeQuestionAnswersPart3 } from './LifeQuestionAnswersPart3';
import { createExternalUserIdAsync } from '../../../lib/user-tracking/utils';
import Popup from '../../Components/Popup';
import WaffleText from '../../Components/WaffleText';
import { ErrorText } from '../../Components/ErrorText';
import { CreateQuoteValidationError } from '../LifeQuoteScreen/models/CreateQuoteValidationError';
import LifeContainer from '../../Helper/LifeContainer';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';

const ContainerOne = styled.div`
  text-align: center;
  flex-direction: column;
  justify-content: center;
`;
export default function LifeQuoteQuestionsScreen({
  navigation,
}: StackScreenProps<
  RootStackParamList,
  'Life Quote Questions'
>): React.ReactElement {
  const { isGuest } = useAuthContext();
  const apolloClient = useApolloClient();
  useLadderLogoNavigationHeader(
    navigation,
    LifeQuoteQuestionsScreenName,
    apolloClient,
    isGuest
  );

  const { user } = useAuthContext();
  const [restoredQuoteId, setRestoredQuoteId] = useState('');
  const [part1Answers, setPart1Answers] = useState({
    annualIncome: '',
    dateOfBirth: '',
    email: user.email ?? '',
    firstName: user.firstName ?? '',
    gender: null,
    height: null,
    lastName: user.lastName ?? '',
    weight: '',
    zipCode: user.zip ? formatZip(user.zip) : '',
  });
  const userTracking = useUserTracking();

  const [createQuoteError, setCreateQuoteError] = useState({
    modalIsOpen: false,
    title: '',
    type: null,
    message: '',
  });

  const [part2Answers, setPart2Answers] = useState({
    hasBiologicalMedicalConditions: false,
    tobacco: null,
  });

  const [part3Answers, setPart3Answers] = useState({
    coverage: null,
    yearsOfCoverage: null,
  });

  const [quoteQuestionSection, setQuoteQuestionSection] = useState(1);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        try {
          const policy = await getLifePolicyMetaDataAsync(apolloClient);

          setRestoredQuoteId(policy.ExternalQuoteId);
        } catch (e) {
          // no-op
        }
      })();
    }, [])
  );

  async function onCreateQuote({ yearsOfCoverage, coverage }) {
    // Use this to fast forward through the question form page
    // const _createQuoteRequest: QuoteRequest = {
    //   email: `acoustic77461+${faker.datatype.number()}@gmail.com`,
    //   first_name: faker.name.firstName(),
    //   last_name: faker.name.lastName(),
    //   zipcode: '94112',
    //   coverage: 500000,
    //   tobacco_use: 'never',
    //   household_income: 120000,
    //   term: 15,
    //   external_id: faker.datatype.number(7777777).toString(),
    //   family_history: false,
    //   weight_pounds: 200,
    //   date_of_birth: '1980-11-24',
    //   height_inches: 69,
    //   sex: 'male',
    // };

    // Temp for helping with debugging
    // const _createQuoteRequest = createQuoteRequest;

    try {
      const externalId = await createExternalUserIdAsync(user.id);

      const quoteRequest: QuoteRequest = {
        coverage: coverage,
        date_of_birth: part1Answers.dateOfBirth,
        email: part1Answers.email,
        external_id: externalId,
        family_history: part2Answers.hasBiologicalMedicalConditions,
        first_name: part1Answers.firstName,
        height_inches: part1Answers.height,
        household_income: Number(part1Answers.annualIncome),
        last_name: part1Answers.lastName,
        sex: part1Answers.gender,
        term: yearsOfCoverage,
        tobacco_use: part2Answers.tobacco,
        weight_pounds: Number(part1Answers.weight),
        zipcode: part1Answers.zipCode,
      };

      let quote;
      if (restoredQuoteId) {
        quote = await updateQuote(restoredQuoteId, quoteRequest);
      } else {
        quote = await createQuote(quoteRequest);
      }

      if (quote.quote_status.valid && quote.quotes.length === 0) {
        navigation.navigate(LifeApplicationDeclined);
        return;
      } else if (quote.quote_status.valid) {
        navigation.navigate(LifeQuoteScreenName);
        return;
      } else {
        // TODO: Handle invalid form requests
      }
    } catch (e) {
      if (e instanceof CreateQuoteValidationError) {
        setCreateQuoteError({
          modalIsOpen: true,
          type: 'validation',
          title:
            'Invalid Input. When you exit this message, please make sure to click the back button to update the desired fields mentioned below.',
          message: e.message,
        });
      } else {
        setCreateQuoteError({
          modalIsOpen: true,
          type: 'unknown',
          title: 'Oh no! An Error occurred!',
          message:
            'Please try again, if the error continues please email us at contact@waffle-labs.com',
        });
      }
    }
  }

  async function onSubmitPart1Answers(answers: LifeQuestionAnswersPart1) {
    document.getElementById('life-container').scrollTop = 0;
    userTracking.publishQuoteQuestionSectionAnswered(quoteQuestionSection);
    setPart1Answers(answers);
    setQuoteQuestionSection(quoteQuestionSection + 1);
  }

  async function onSubmitPart2Answers(answers: LifeQuestionAnswersPart2) {
    document.getElementById('life-container').scrollTop = 0;
    userTracking.publishQuoteQuestionSectionAnswered(quoteQuestionSection);
    setPart2Answers(answers);
    setQuoteQuestionSection(quoteQuestionSection + 1);
  }

  async function onSubmitPart3Answers(answers: LifeQuestionAnswersPart3) {
    userTracking.publishQuoteQuestionSectionAnswered(quoteQuestionSection);
    setPart3Answers(answers);
    await onCreateQuote({
      yearsOfCoverage: answers.yearsOfCoverage,
      coverage: answers.coverage,
    });
  }

  return (
    <LifeContainer id={'life-container'}>
      {/*<LifeQuoteQuestionView onSubmit={onCreateQuote} />*/}
      <Spacer y={2} />
      {quoteQuestionSection === 1 ? (
        <LifeQuoteQuestionsPart1View
          onSubmit={onSubmitPart1Answers}
          {...part1Answers}
        />
      ) : null}
      {quoteQuestionSection === 2 ? (
        <LifeQuoteQuestionsPart2View
          onSubmit={onSubmitPart2Answers}
          onBack={async () => {
            document.getElementById('life-container').scrollTop = 0;
            setQuoteQuestionSection(quoteQuestionSection - 1);
          }}
          {...part2Answers}
        />
      ) : null}
      {quoteQuestionSection === 3 ? (
        <LifeQuoteQuestionsPart3View
          onSubmit={onSubmitPart3Answers}
          onBack={async (answers) => {
            document.getElementById('life-container').scrollTop = 0;
            setQuoteQuestionSection(quoteQuestionSection - 1);
            setPart3Answers(answers);
          }}
          {...part3Answers}
        />
      ) : null}
      <Spacer y={3} />
      <LifeDisclosure />
      <Popup
        viewStyle={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
        hasSideMenu={false}
        visible={createQuoteError.modalIsOpen}
        onDismiss={() =>
          setCreateQuoteError({ ...createQuoteError, modalIsOpen: false })
        }>
        <Spacer y={2} />
        <WaffleText style={{ textAlign: 'center', fontSize: 18 }}>
          {createQuoteError.title}
        </WaffleText>
        <Spacer y={3} />
        {createQuoteError.type === 'validation' &&
          createQuoteError.message.split(',').map((m, i) => (
            <ErrorText key={i} style={{ fontSize: 12, textAlign: 'center' }}>
              {m}
            </ErrorText>
          ))}
        {createQuoteError.type === 'unknown' && (
          <ContainerOne>
            <WaffleText
              style={{
                textAlign: 'center',
                fontSize: 12,
                color: '#000000',
              }}>
              {createQuoteError.message}
            </WaffleText>
          </ContainerOne>
        )}
      </Popup>
    </LifeContainer>
  );
}
