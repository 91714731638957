import { StylesConfig } from 'react-select';
import { WaffleTextFamily } from '../../Components/WaffleText';
export const defaultStylesConfig: StylesConfig = {
  option: (provided, _) => ({
    ...provided,
    fontFamily: WaffleTextFamily,
  }),
  menuList: (provided, _) => ({
    ...provided,
    fontFamily: WaffleTextFamily,
  }),
  multiValue: (provided, _) => ({
    ...provided,
    fontFamily: WaffleTextFamily,
  }),
  multiValueLabel: (provided, _) => ({
    ...provided,
    fontFamily: WaffleTextFamily,
  }),
  singleValue: (provided, _) => ({
    ...provided,
    fontFamily: WaffleTextFamily,
  }),
  container: (provided, _) => ({
    ...provided,
    width: '100%',
    height: 'auto',
    minHeight: 44,
  }),
  control: (provided, _) => ({
    ...provided,
    borderRadius: 8,
    borderColor: 'rgb(222, 222, 222)',
    height: 'auto',
    minHeight: 44,
  }),
  placeholder: (provided, _) => ({
    ...provided,
    fontFamily: WaffleTextFamily,
    color: 'rgb(179,179,179)',
  }),
  input: (provided, _) => ({
    ...provided,
    fontFamily: WaffleTextFamily,
    '& input': {
      font: 'inherit',
    },
  }),
};
