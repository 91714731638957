import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AnswerOption,
  AnswerValueScalar,
  Question,
  AnswerValue,
} from './models/application';
import { KeyboardType, StyleSheet, View } from 'react-native';
import Select from '../LifeQuoteQuestionsScreen/Select';
import { PressableRadioGroup } from './PressableRadioGroup';
import { Breadcrumbs } from './Breadcrumbs';
import {
  isNullOrUndefined,
  mappedAnswerValueOptions,
} from './utils/question-helpers';
import { PressableRadioGroupMulti } from './PressableRadioGroupMulti';
import { Spacer } from '../../Components/SimpleComponents';
import { QuestionExternalDependencies } from './utils/ladder-life-coverage.constants';
import { formatText } from './utils/display-hint-formatter-factory';
import { fmtISOToDisplayDt, onDateChange } from './utils/date-format-helper';
import { TextIconInput } from '../../Components/TextIconInput';
import { Horizontal } from '../../Components/SimpleLayouts';
import { Default } from './utils/question-default-empty-values.constant';
import WaffleText, { WaffleTextFamily } from '../../Components/WaffleText';
import SelectAsync from '../LifeQuoteQuestionsScreen/SelectAsync';
import { ErrorText } from '../../Components/ErrorText';
import {
  autocompleteAppErrMsg,
  errMsg,
} from '../LifeQuoteQuestionsScreen/utils/api-error-message';
import { defaultStylesConfig } from '../LifeQuoteQuestionsScreen/react-select-default.constants';
import Sentry from '../../Sentry';
import { ErrorContainer } from '../../Components/ErrorContainer';
import { WaffleOrange } from '../../Constants/Style';

type Props = {
  formQuestion: Question;
  onValueChange: (id: string, newValue: AnswerValue) => void;
  value: AnswerValue;
  errors?: object;
  groupFormData?: object;
  parentQuestion?: Question; // Special cases like group that needs to know if a parent exists
  externalDependencies?: QuestionExternalDependencies;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const neverReached = (_never: never) => {};

type HeaderProps = { formQuestion: Question; parentQuestion: Question };
const Header = (headerProps: HeaderProps): React.ReactElement => {
  const { breadcrumbs, caption, subcaption } = headerProps.formQuestion;
  const { parentQuestion } = headerProps;
  const parentHasCaption =
    !isNullOrUndefined(parentQuestion?.caption) &&
    parentQuestion.caption !== '';
  return (
    <React.Fragment>
      {breadcrumbs && breadcrumbs.join(',') !== caption ? (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      ) : null}
      {!parentHasCaption && caption && (
        <WaffleText style={styles.textStyle}>{caption}</WaffleText>
      )}
      {subcaption && (
        <>
          <WaffleText style={{ textAlign: 'center', fontSize: 12 }}>
            {' '}
            {subcaption}{' '}
          </WaffleText>
          <Spacer y={2} />
        </>
      )}
    </React.Fragment>
  );
};

type FooterProps = { formQuestion: Question; errors: object };
const Footer = (footerProps: FooterProps): React.ReactElement => {
  const { id, subtext } = footerProps.formQuestion;
  const { errors } = footerProps;
  return (
    <React.Fragment>
      {errors && errors[id] && (
        <>
          <ErrorText style={{ height: 'auto' }}>{errors[id]}</ErrorText>
          <Spacer y={2} />
        </>
      )}
      {subtext && (
        <>
          <WaffleText style={styles.subtext}>{subtext} </WaffleText>
          <Spacer y={2} />
        </>
      )}
    </React.Fragment>
  );
};
const Boolean = (props: Props): React.ReactElement => {
  const { id } = props.formQuestion;
  const { onValueChange } = props;
  const value = props.value;

  const answer_options = [
    { value: false, text: 'No' } as AnswerOption,
    { value: true, text: 'Yes' } as AnswerOption,
  ];
  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />

      <PressableRadioGroup
        value={value as AnswerValueScalar}
        answerOptions={answer_options}
        onValueChange={function (changedValue: AnswerValueScalar): void {
          onValueChange(id, changedValue);
        }}
      />

      <Footer formQuestion={props.formQuestion} errors={props.errors} />
    </React.Fragment>
  );
};

const GroupQuestions = (props: Props): React.ReactElement => {
  if (!props.formQuestion.subquestions) {
    throw new Error('Subquestions are expected but none were provided');
  }

  if (!props.groupFormData) {
    throw new Error('Expected form data for group type questions');
  }
  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      {props.formQuestion.subquestions.map((q: Question, i: number) => (
        <QuestionComponentFactoryV2
          key={i}
          formQuestion={q}
          parentQuestion={props.formQuestion}
          externalDependencies={props.externalDependencies}
          onValueChange={props.onValueChange}
          value={
            Object.prototype.hasOwnProperty.call(props.groupFormData, q.id)
              ? props.groupFormData[q.id]
              : null
          }
          errors={props.errors}
        />
      ))}

      <Footer formQuestion={props.formQuestion} errors={props.errors} />
    </React.Fragment>
  );
};

// Used for checkbox display_hint
const RadioGroup = (props: Props): React.ReactElement => {
  const { id, answer_options } = props.formQuestion;
  const { onValueChange, value } = props;

  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      <PressableRadioGroup
        value={value as AnswerValueScalar}
        answerOptions={answer_options}
        onValueChange={function (changedValue: AnswerValueScalar): void {
          onValueChange(id, changedValue);
        }}
      />
      <Footer formQuestion={props.formQuestion} errors={props.errors} />
    </React.Fragment>
  );
};

const DateInputElement = (props: Props): React.ReactElement => {
  const { id, caption } = props.formQuestion;
  const { onValueChange, parentQuestion, value: isoDate } = props;
  const parentHasCaption =
    !isNullOrUndefined(parentQuestion?.caption) &&
    parentQuestion.caption !== '';
  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      <Horizontal>
        <TextIconInput
          value={useMemo(() => fmtISOToDisplayDt(isoDate as string), [isoDate])}
          placeholder={parentHasCaption ? caption : 'MM/DD/YYYY'}
          onChangeText={(dateValue: string) =>
            onValueChange(id, onDateChange(dateValue))
          }
          optional={false}
        />
      </Horizontal>
      <Footer formQuestion={props.formQuestion} errors={props.errors} />
    </React.Fragment>
  );
};

type TextInputElementProps = Props & {
  keyboardType: KeyboardType;
};
const TextInputElement = (props: TextInputElementProps): React.ReactElement => {
  const { id, caption } = props.formQuestion;
  const { onValueChange, parentQuestion, value, keyboardType } = props;
  const parentHasCaption =
    !isNullOrUndefined(parentQuestion?.caption) &&
    parentQuestion.caption !== '';
  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      <Horizontal>
        <TextIconInput
          otherProps={{ keyboardType: keyboardType }}
          value={value as string}
          placeholder={parentHasCaption ? caption : ''}
          onChangeText={(changedValue: string) => {
            onValueChange(id, changedValue);
          }}
        />
      </Horizontal>
      <Footer formQuestion={props.formQuestion} errors={props.errors} />
    </React.Fragment>
  );
};

const SingleSelect = (props: Props): React.ReactElement => {
  const { id, caption, answer_options } = props.formQuestion;
  const { onValueChange, parentQuestion, value } = props;
  const parentHasCaption =
    !isNullOrUndefined(parentQuestion?.caption) &&
    parentQuestion.caption !== '';

  if (!answer_options || answer_options.length === 0) {
    throw new Error('Answer Options should not be null/undefined/empty!');
  }

  const selectedOption =
    answer_options.find((option) => option.value === value) || null;
  // Pass in null or else it retains the previous value???

  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      <Select
        value={selectedOption}
        isClearable={true}
        options={answer_options}
        getOptionLabel={(option) => `${option.text}`}
        onChange={(v) => {
          onValueChange(id, v ? v.value : Default.Singleselect);
        }}
        placeholder={parentHasCaption ? caption : 'Please select'}
      />
      <Footer formQuestion={props.formQuestion} errors={props.errors} />
      <Spacer y={1} />
    </React.Fragment>
  );
};

const exclusiveValueKey = 'exclusive_value';
const MultiSelect = (props: Props): React.ReactElement => {
  const { id, answer_options, validation } = props.formQuestion;
  const { onValueChange, value } = props;
  const hasExclusiveValidation = validation && validation[exclusiveValueKey];

  if (
    !props.formQuestion.answer_options ||
    props.formQuestion.answer_options.length === 0
  ) {
    throw new Error('Answer Options should not be null/undefined/empty!');
  }

  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      <PressableRadioGroupMulti
        selected={value as AnswerValueScalar[]}
        answerOptions={answer_options}
        onValueChange={(changedValues: AnswerValueScalar[]): void => {
          if (
            hasExclusiveValidation &&
            changedValues.includes(validation[exclusiveValueKey])
          ) {
            const newExclusiveOpt = [validation[exclusiveValueKey]];
            onValueChange(id, newExclusiveOpt);
          } else {
            onValueChange(id, changedValues);
          }
        }}
      />
      <Footer formQuestion={props.formQuestion} errors={props.errors} />
    </React.Fragment>
  );
};

const red = '#FF5630';
const customStyling = {
  ...defaultStylesConfig,
  option: (styles, { isDisabled, data }) => {
    if (isDisabled && data.value === '__error') {
      const color = red;
      const backgroundColor = 'white';
      return { ...styles, color, backgroundColor };
    }

    return { ...styles };
  },
};

const AutoCompleteMultiSelect = (props: Props): React.ReactElement => {
  const { id, caption, answer_options_endpoint } = props.formQuestion;
  const {
    onValueChange,
    value: values,
    parentQuestion,
    externalDependencies,
  } = props;

  const parentHasCaption =
    !isNullOrUndefined(parentQuestion?.caption) &&
    parentQuestion.caption !== '';

  const selectedOpts = useMemo(
    () => mappedAnswerValueOptions(values as AnswerValueScalar[]),
    [values]
  );

  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      <SelectAsync
        value={selectedOpts}
        options={selectedOpts}
        isMulti={true}
        getOptionLabel={(option) => `${option.text}`}
        styles={customStyling}
        loadOptions={async (query: string) => {
          try {
            const options = await externalDependencies.getAutoCompleteResults(
              answer_options_endpoint,
              query
            );

            return options;
          } catch (e) {
            // Workaround until promise error handling is natively supported
            // See: https://github.com/JedWatson/react-select/issues/1528
            return [
              {
                isDisabled: true,
                value: '__error',
                text: `${
                  e.response?.data
                    ? autocompleteAppErrMsg(e.response.data)
                    : errMsg(e.message)
                }`,
              },
            ];
          }
        }}
        onChange={(v) => {
          const newValues = v.map((answer) => answer.value);
          onValueChange(id, newValues);
        }}
        placeholder={parentHasCaption ? caption : 'Start typing here'}
      />
      <Footer formQuestion={props.formQuestion} errors={props.errors} />
      <Spacer y={1} />
    </React.Fragment>
  );
};

const Hosted = (props: Props): React.ReactElement => {
  const { id } = props.formQuestion;
  const { externalDependencies } = props;
  const [error, setError] = useState<string>('');

  const onHFError = useCallback((e: any) => {
    console.error(e.message);
    Sentry.captureException(e);
    setError(
      'Hosted field: An error has occurred. You may need to refresh the page and try again.'
    );
    /* If an error does occur, we are dependent on the page getting reloaded for the error to potentially go away
     * In the docs, Ladder life suggests to ask the user to refresh the page and try again.
     */
  }, []);

  useEffect(() => {
    const appOperations = externalDependencies?.getLadderAppOperations();

    if (appOperations === null || appOperations === undefined) {
      throw new Error('Hosted field is missing a required parameter!');
    }

    try {
      const questionElement = appOperations.createQuestionElement(id, {
        style: {
          base: {
            fontFamily: WaffleTextFamily,
            fontSize: '15px',
            linkColor: WaffleOrange,
            linkFontFamily: WaffleTextFamily,
            checkboxCheckedBackgroundColor: WaffleOrange,
          },
        },
      });

      const onErrListenerID = questionElement.on('error', onHFError);
      questionElement.mount('hosted-field');

      return function cleanup() {
        if (questionElement && onErrListenerID) {
          questionElement.off(onErrListenerID);
        }
      };
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      setError(
        'Hosted field: An error occurred while loading an input field, please reload the page and try again.'
      );
    }
  }, [externalDependencies, id, onHFError]);

  return (
    <React.Fragment>
      <Header
        formQuestion={props.formQuestion}
        parentQuestion={props.parentQuestion}
      />
      <View nativeID="hosted-field" />
      {error && <ErrorContainer>{error}</ErrorContainer>}
      <Footer formQuestion={props.formQuestion} errors={props.errors} />
    </React.Fragment>
  );
};

export default function QuestionComponentFactoryV2(
  props: Props
): React.ReactElement {
  const { type } = props.formQuestion;
  switch (type) {
    case 'boolean':
      return (
        <Boolean
          formQuestion={props.formQuestion}
          parentQuestion={props.parentQuestion}
          value={props.value}
          onValueChange={props.onValueChange}
          errors={props.errors}
        />
      );
    case 'text':
      return (
        <TextInputElement
          keyboardType={'default'}
          formQuestion={props.formQuestion}
          parentQuestion={props.parentQuestion}
          value={props.value}
          onValueChange={(id: string, changedValue: string) => {
            if (props.formQuestion.display_hint) {
              props.onValueChange(
                id,
                formatText(changedValue, props.formQuestion.display_hint)
              );
            } else {
              props.onValueChange(id, changedValue);
            }
          }}
          errors={props.errors}
        />
      );
    case 'integer':
      return (
        <TextInputElement
          keyboardType={'number-pad'}
          formQuestion={props.formQuestion}
          parentQuestion={props.parentQuestion}
          value={
            props.formQuestion.display_hint
              ? formatText(
                  props.value.toString(),
                  props.formQuestion.display_hint
                )
              : props.value
          }
          onValueChange={(id: string, changedValue: string) => {
            const isEmptyValue =
              changedValue === undefined ||
              changedValue === null ||
              changedValue === '';

            const number = Math.min(
              Number(changedValue.replace(/[^0-9]/g, '')),
              Number.MAX_SAFE_INTEGER
            );

            if (isEmptyValue || isNaN(number)) {
              props.onValueChange(id, '');
            } else {
              props.onValueChange(id, number);
            }
          }}
          errors={props.errors}
        />
      );
    case 'date':
      return (
        <DateInputElement
          formQuestion={props.formQuestion}
          parentQuestion={props.parentQuestion}
          value={props.value}
          onValueChange={props.onValueChange}
          errors={props.errors}
        />
      );
    case 'singleselect':
      if (
        props.formQuestion.display_hint === 'checkbox' ||
        props.formQuestion?.answer_options?.length <= 5
      ) {
        return (
          <RadioGroup
            formQuestion={props.formQuestion}
            parentQuestion={props.parentQuestion}
            value={props.value}
            onValueChange={props.onValueChange}
            errors={props.errors}
          />
        );
      }

      return (
        <SingleSelect
          formQuestion={props.formQuestion}
          parentQuestion={props.parentQuestion}
          value={props.value}
          onValueChange={props.onValueChange}
          errors={props.errors}
        />
      );
    case 'multiselect':
      if (props.formQuestion.uses_autocomplete_endpoint) {
        return (
          <AutoCompleteMultiSelect
            formQuestion={props.formQuestion}
            parentQuestion={props.parentQuestion}
            externalDependencies={props.externalDependencies}
            value={props.value}
            onValueChange={props.onValueChange}
            errors={props.errors}
          />
        );
      }
      return (
        <MultiSelect
          formQuestion={props.formQuestion}
          parentQuestion={props.parentQuestion}
          onValueChange={props.onValueChange}
          value={props.value}
          errors={props.errors}
        />
      );
    case 'group':
      return (
        <GroupQuestions
          formQuestion={props.formQuestion}
          onValueChange={props.onValueChange}
          externalDependencies={props.externalDependencies}
          groupFormData={props.groupFormData}
          value={props.value}
          errors={props.errors}
        />
      );
    case 'hosted':
      return (
        <Hosted
          formQuestion={props.formQuestion}
          parentQuestion={props.parentQuestion}
          onValueChange={props.onValueChange}
          externalDependencies={props.externalDependencies}
          value={props.value}
          errors={props.errors}
        />
      );
    default:
      neverReached(type); // Prevent unhandled types in the IDE
  }
  return <React.Fragment />;
}

const styles = StyleSheet.create({
  textStyle: {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
    fontWeight: '600',
    fontSize: 20,
    lineHeight: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  subtext: {
    fontSize: 15,
    lineHeight: 30,
    fontWeight: '300',
    fontFamily: 'Montserrat,sans-serif',
    color: '#707070',
  },
  commonBorderStyle: {
    backgroundColor: '#FFFFFF',
    border: '3px solid #F98D46',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
  },
});
