import React from 'react';
import { View, StyleSheet } from 'react-native';
import { PressableRadio } from '../../Components/PressableRadio';
import { Spacer } from '../../Components/SimpleComponents';
import { AnswerOption, AnswerValueScalar } from './models/application';
import WaffleText from '../../Components/WaffleText';

type Props = {
  value: AnswerValueScalar;
  answerOptions: Array<AnswerOption>;
  onValueChange: (value: AnswerValueScalar) => void;
  optionTestIDPrefix?: string;
  radioGroupTestID?: string;
};

export const PressableRadioGroup = (props: Props): React.ReactElement => {
  const {
    value,
    onValueChange,
    optionTestIDPrefix = 'pressable-radio-',
    radioGroupTestID = 'pressable-radio-group',
    answerOptions,
  } = props;

  if (!answerOptions || answerOptions.length === 0) {
    throw new Error('AnswerOptions is null/undefined or empty');
  }

  return (
    <View
      accessibilityRole="radiogroup"
      style={styles.radioGroupContainerStyle}>
      {answerOptions.map(
        (answerOption: AnswerOption, i: number, answerOpts: AnswerOption[]) => (
          <React.Fragment key={`fragment_${i}`}>
            <PressableRadio
              key={`radio_${i}`}
              selected={value === answerOption.value}
              onPress={() => {
                onValueChange(answerOption.value);
              }}>
              <WaffleText
                key={`text_${i}`}
                style={{
                  color: '#72777A',
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  maxHeight: '100%', // Specify max height so overflow-y works properly
                  minWidth: 0, // Specify min-width so flex wrap works properly on long words or words with no spaces
                }}>
                {' '}
                {answerOption.text}{' '}
              </WaffleText>
            </PressableRadio>
            {answerOpts.length - 1 !== i && (
              <Spacer key={`spacer_${i}`} y={2} />
            )}
          </React.Fragment>
        )
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  radioGroupContainerStyle: {
    width: '100%',
    height: 'auto', // height 100% overlaps with other CSS in the chrome grid layout
    alignItems: 'center',
  },
});
