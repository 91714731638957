import { AnswerValueScalar } from '../models/application';

export class Default {
  /** Default value for boolean type question components */
  static get Boolean(): boolean {
    return false;
  }
  /** Default value for integer type question components. Why is integer an empty string value? Component is expected to be text input. */
  static get Integer(): string {
    return '';
  }
  /** Default value for text type question components. */
  static get Text(): string {
    return '';
  }
  /** Default value for date type question components. */
  static get Date(): string {
    return '';
  }
  /** Default value for singleselect type question components. */
  static get Singleselect(): AnswerValueScalar | null {
    return null;
  }
  /** Default value for multiselect type question components. */
  static get Multiselect(): Array<AnswerValueScalar> {
    return new Array<AnswerValueScalar>();
  }
  /** Default value for hosted type question components. */
  static get Hosted(): boolean {
    // Submit value of 'true' to answer a hosted field question
    return true;
  }
}
