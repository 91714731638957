import React, { useState } from 'react';
import { useAuthContext } from '../AuthProvider';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import { validationErr400 } from './api-error.constants';
import Sentry from '../../Sentry';
import { errMsg, httpErrMsg } from './utils/api-error-message';
import { Spacer } from '../../Components/SimpleComponents';
import WaffleText from '../../Components/WaffleText';
import Select from './Select';
import { Coverages, Terms } from './constants';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { ErrorContainer } from '../../Components/ErrorContainer';
import styled from 'styled-components';
import { WaffleOrange } from '../../Constants/Style';
import { LifeQuestionAnswersPart3 } from './LifeQuestionAnswersPart3';
import { defaultStylesConfig } from './react-select-default.constants';
import { valueExists } from './utils/validators.';

type Props = {
  onSubmit: (quoteRequest: LifeQuestionAnswersPart3) => Promise<void>;
  onBack: (answer: LifeQuestionAnswersPart3) => Promise<void>;
  coverage: number;
  yearsOfCoverage: number;
};

const Card = styled.div`
  box-shadow: 0px 4px 28px -9px rgba(0, 0, 0, 0.25);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
  max-width: 375px;
  width: 85%;
  padding: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InputContainer = styled.div`
  background: #f2f2f2;
  margin: 23px;
  display: flex;
  height: 197px;
  width: 100%;
  //flex: 1;
  justify-content: center;
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
`;

type NumericOption = {
  label: string;
  value: number;
};
const CoverageOptions: NumericOption[] = Coverages.map((v) => ({
  label: v.toLocaleString(),
  value: v,
}));

const TermOptions: NumericOption[] = Terms.map((t) => ({
  value: t,
  label: t.toLocaleString(),
}));

export default function LifeQuoteQuestionsPart3View({
  onSubmit,
  onBack,
  ...props
}: Props): React.ReactElement {
  const [coverage, setCoverage] = useState<{
    value: number;
    isValid: boolean;
  }>({
    value: props.coverage,
    isValid: valueExists(props.coverage),
  });
  const [yearsOfCoverage, setYearsOfCoverage] = useState<{
    value: number;
    isValid: boolean;
  }>({
    value: props.yearsOfCoverage,
    isValid: valueExists(props.yearsOfCoverage),
  });
  const [error, setError] = useState<string>('');
  const tracker = useUserTracking();
  const authContext = useAuthContext();

  async function _onSubmit() {
    if (error) {
      setError('');
    }

    // const externalUserId = await createExternalUserIdAsync(authContext.user.id);
    try {
      await onSubmit({
        coverage: coverage.value,
        yearsOfCoverage: yearsOfCoverage.value,
      });
    } catch (e) {
      if (e.response?.status !== validationErr400) {
        // Mainly care about capturing non-validation type errors
        Sentry.captureException(e);
      }
      setError(e.response ? httpErrMsg(e.response.status) : errMsg(e.message));
    }
  }

  // @ts-ignore
  return (
    <Card>
      <Spacer y={2} />
      <WaffleText style={{ fontSize: 30, textAlign: 'center' }}>
        Part 3: Customize Policy
      </WaffleText>
      <InputContainer>
        <SelectContainer>
          <WaffleText style={{ fontSize: 20, textAlign: 'center' }}>
            Coverage Amount
          </WaffleText>
          <Spacer y={2} />
          <Select
            value={CoverageOptions.find((c) => c.value === coverage.value)}
            onChange={(v: NumericOption) =>
              setCoverage({
                value: v.value,
                isValid: valueExists(v.value),
              })
            }
            placeholder="Amount"
            options={CoverageOptions}
            styles={{
              ...defaultStylesConfig,
              control: (provided, _) => ({
                ...provided,
                borderRadius: 8,
                borderColor: '#B5B5B5',
                height: 'auto',
                minHeight: 44,
              }),
            }}
          />
        </SelectContainer>
      </InputContainer>
      <InputContainer>
        <SelectContainer>
          <WaffleText style={{ fontSize: 20, textAlign: 'center' }}>
            Years of Coverage
          </WaffleText>
          <Spacer y={2} />
          <Select
            value={TermOptions.find((t) => t.value === yearsOfCoverage.value)}
            onChange={(v: NumericOption) =>
              setYearsOfCoverage({
                value: v.value,
                isValid: valueExists(v.value),
              })
            }
            placeholder="Years"
            options={TermOptions}
            styles={{
              ...defaultStylesConfig,
              control: (provided, _) => ({
                ...provided,
                borderRadius: 8,
                borderColor: '#B5B5B5',
                height: 'auto',
                minHeight: 44,
              }),
            }}
          />
        </SelectContainer>
      </InputContainer>
      <Spacer y={2} />
      <ButtonContainer>
        <WaffleButtonAsync
          style={{
            borderRadius: 13,
            borderWidth: 1,
            borderColor: WaffleOrange,
            maxWidth: 146,
            background: 'white',
          }}
          textStyle={{
            color: WaffleOrange,
          }}
          onPress={() => {
            return onBack({
              coverage: coverage.value,
              yearsOfCoverage: yearsOfCoverage.value,
            });
          }}
          name="Back"
        />
        <Spacer x={2} />
        <WaffleButtonAsync
          disabled={!(coverage.isValid && yearsOfCoverage.isValid)}
          style={{
            borderRadius: 13,
            maxWidth: 146,
          }}
          onPress={_onSubmit}
          name="Next"
        />
      </ButtonContainer>
      <Spacer y={2} />

      {error && <ErrorContainer> {error} </ErrorContainer>}
    </Card>
  );
}
