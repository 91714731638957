import { StackScreenProps } from '@react-navigation/stack';
import {
  LifeApplicationScreenName,
  LifeApplicationDeclined,
  LifeQuoteQuestionsScreenName,
  RootStackParamList,
} from '../../../screen-config';
import React, { useCallback, useState } from 'react';
import {
  getQuote,
  QuoteResponse,
  updateQuote,
} from '../LifeQuoteScreen/models/application';
import QuoteSummaryView from './QuoteSummaryView';
import { useLadderLogoNavigationHeader } from '../LifeApplicationReviewScreen/useLadderNavigationHeader';
import { getLifePolicyMetaDataAsync } from '../../../lib/life';
import { Spacer } from '../../Components/SimpleComponents';
import LifeDisclosure from '../../Components/LifeDisclosure';
import { useFocusEffect } from '@react-navigation/native';
import { useApolloClient } from '@apollo/client';
import {
  errMsg,
  httpErrMsg,
} from '../LifeQuoteQuestionsScreen/utils/api-error-message';
import { ErrorContainer } from '../../Components/ErrorContainer';
import Sentry from '../../Sentry';
import { useAuthContext } from '../AuthProvider';
import LifeContainer from '../../Helper/LifeContainer';

const defaultQuoteInfoState = () => {
  return {
    quotes: [],
    quoteId: null,
    quoteStatus: null,
    suggestedQuoteIndex: 0,
    isLoading: true,
    error: '',
  };
};

export default function LifeQuoteScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Life Quote'>): React.ReactElement {
  const { isGuest } = useAuthContext();
  const apolloClient = useApolloClient();
  useLadderLogoNavigationHeader(
    navigation,
    LifeQuoteQuestionsScreenName,
    apolloClient,
    isGuest
  );

  const [quoteInfo, setQuoteInfo] = useState(defaultQuoteInfoState());

  /*
   * Assumes this happens only on page load since it sets defaultQuoteInfoState without needing to list the quoteInfo as a hook dependency
   */
  async function tryGetQuote(
    externalQuoteID: string
  ): Promise<QuoteResponse | null> {
    try {
      const quote = await getQuote(externalQuoteID);

      if (!quote) {
        throw new Error(`Quote was not found`);
      }

      return quote;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);

      setQuoteInfo({
        ...defaultQuoteInfoState(),
        error: e.response ? httpErrMsg(e.response.status) : errMsg(e.message),
        isLoading: false,
      });

      return null;
    }
  }

  useFocusEffect(
    useCallback(() => {
      (async () => {
        try {
          const policy = await getLifePolicyMetaDataAsync(apolloClient);

          const quote = await tryGetQuote(policy?.ExternalQuoteId);

          if (!quote) {
            return;
          }

          if (quote.quote_status.valid && quote.quotes.length === 0) {
            navigation.navigate(LifeApplicationDeclined);
            return;
          }

          const suggestedQuoteIndex = policy?.RequestedQuoteTerm
            ? quote.quotes.findIndex(
                (q) => q.term === parseInt(policy?.RequestedQuoteTerm, 10)
              )
            : 0;

          setQuoteInfo({
            ...quoteInfo,
            suggestedQuoteIndex:
              suggestedQuoteIndex === -1 ? 0 : suggestedQuoteIndex,
            quotes: [...quote.quotes],
            quoteStatus: quote.quote_status,
            quoteId: quote.id,
            isLoading: false,
            error: '',
          });
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);

          setQuoteInfo({
            ...defaultQuoteInfoState(),
            error: e.response
              ? httpErrMsg(e.response.status)
              : errMsg(e.message),
            isLoading: false,
          });
        }
      })();
    }, [])
  );

  async function onRequestApplication(selectedQuoteIndex: number) {
    try {
      if (quoteInfo.suggestedQuoteIndex !== selectedQuoteIndex) {
        await updateQuote(quoteInfo.quoteId, {
          term: quoteInfo.quotes[selectedQuoteIndex].term,
        });
      }

      navigation.navigate(LifeApplicationScreenName);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  if (quoteInfo.error) {
    return (
      <LifeContainer>
        <ErrorContainer>
          {`An error occurred while retrieving your quote coverage information: ${quoteInfo.error}.\n\nPlease reload the page and try again.`}
        </ErrorContainer>
      </LifeContainer>
    );
  }

  return (
    <LifeContainer>
      <Spacer y={4} />
      <QuoteSummaryView
        isLoading={quoteInfo.isLoading}
        quotes={quoteInfo.quotes}
        suggestedQuoteIndex={quoteInfo.suggestedQuoteIndex}
        onRequestApplication={onRequestApplication}
      />
      <Spacer y={3} />
      <LifeDisclosure />
    </LifeContainer>
  );
}
