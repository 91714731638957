import WaffleText from '../../Components/WaffleText';
import React from 'react';
import styled from 'styled-components';
import { WaffleOrange } from '../../Constants/Style';
import LoadingRectangle from '../../Components/LoadingRectangle';
import { Spacer } from '../../Components/SimpleComponents';

const SuggestedQuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 225px;
  padding: 12px;
  align-items: center;
  justify-content: center;
  background: ${WaffleOrange};
  border-radius: 50%;
`;

type Props = {
  term: number;
  premium: string;
  coverage: string;
  isLoading: boolean;
};

export const LoadingSuggestedQuote = () => {
  return (
    <>
      <LoadingRectangle width={160} height={50} />
      <Spacer y={2} />
      <LoadingRectangle width={90} height={30} />
    </>
  );
};

export default function SuggestedQuote({
  term,
  premium,
  coverage,
  isLoading,
}: Props): React.ReactElement {
  return (
    <SuggestedQuoteContainer>
      {isLoading ? (
        <LoadingSuggestedQuote />
      ) : (
        <>
          <WaffleText
            style={{ fontSize: 45, color: 'white', textAlign: 'center' }}>
            {premium}
          </WaffleText>
          <WaffleText
            style={{ fontSize: 14, color: 'white', textAlign: 'center' }}>
            {'per month'}
          </WaffleText>
          <WaffleText
            style={{ fontSize: 18, color: 'white', textAlign: 'center' }}>
            {term + ' years'}
          </WaffleText>
          <WaffleText
            style={{ fontSize: 18, color: 'white', textAlign: 'center' }}>
            {coverage}
          </WaffleText>
        </>
      )}
    </SuggestedQuoteContainer>
  );
}
