import styled from 'styled-components';
import WaffleText from '../../Components/WaffleText';
import React, { useEffect, useState } from 'react';
import { Spacer } from '../../Components/SimpleComponents';
import { Quote } from '../LifeQuoteScreen/models/application';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { mapQuoteToQuoteViewModel } from '../LifeQuoteQuestionsScreen/utils/quoteMapper';
import SuggestedQuote from '../LifeQuoteQuestionsScreen/SuggestedQuote';
import { ErrorContainer } from '../../Components/ErrorContainer';
import {
  errMsg,
  httpErrMsg,
} from '../LifeQuoteQuestionsScreen/utils/api-error-message';
import Sentry from '../../Sentry';
import { WaffleBackground } from '../../Constants/Style';
import QuoteOptionsContainer from '../LifeQuoteQuestionsScreen/QuoteOptionContainer';

const Card = styled.div`
  background: ${WaffleBackground};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 375px;
  padding: 4px;
`;

type Props = {
  isLoading: boolean;
  quotes: Array<Quote>;
  suggestedQuoteIndex: number;
  onRequestApplication: (index: number) => Promise<void>;
};

export default function QuoteSummaryView({
  quotes = [],
  suggestedQuoteIndex,
  onRequestApplication,
  isLoading,
}: Props): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = useState(suggestedQuoteIndex);
  const [error, setError] = useState<string>('');
  const suggestedQuote = mapQuoteToQuoteViewModel(quotes[selectedIndex]);

  useEffect(() => {
    setSelectedIndex(suggestedQuoteIndex);
  }, [suggestedQuoteIndex]);

  async function _onRequestApplication() {
    try {
      if (error) {
        setError('');
      }

      await onRequestApplication(selectedIndex);
    } catch (e) {
      Sentry.captureException(e);
      setError(e.response ? httpErrMsg(e.response.status) : errMsg(e.message));
    }
  }

  function onSelectIndex(term: number) {
    const newIndex = quotes.findIndex((q) => q.term === term);
    setSelectedIndex(newIndex);
  }

  return (
    <Card>
      <WaffleText style={{ fontSize: 40, textAlign: 'center' }}>
        Here is your estimated price!
      </WaffleText>
      <WaffleText style={{ fontSize: 18 }}>
        Based on what you selected:
      </WaffleText>
      <Spacer y={6} />
      <SuggestedQuote
        isLoading={isLoading}
        term={suggestedQuote.term}
        premium={suggestedQuote.premium}
        coverage={suggestedQuote.coverage}
      />
      <Spacer y={6} />
      <WaffleText style={{ textAlign: 'center', fontSize: 30 }}>
        {'Other Options: '}
      </WaffleText>
      <Spacer y={4} />
      <QuoteOptionsContainer
        isLoading={isLoading}
        onTermClicked={onSelectIndex}
        quotes={quotes
          .filter((_, i) => i !== selectedIndex)
          .map(mapQuoteToQuoteViewModel)}
      />
      <Spacer y={4} />
      <WaffleButtonAsync
        style={{
          borderRadius: 5,
        }}
        onPress={() => _onRequestApplication()}
        name="continue"
      />
      <Spacer y={4} />
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </Card>
  );
}
