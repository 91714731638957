import React from 'react';
import WaffleText from '../../Components/WaffleText';

type Props = {
  breadcrumbs: string[];
};

export const Breadcrumbs = (props: Props): React.ReactElement => {
  const { breadcrumbs } = props;

  return (
    breadcrumbs?.length > 0 && (
      <React.Fragment>
        <WaffleText style={{ fontSize: 14, opacity: 0.5 }}>
          {breadcrumbs.reduce(
            (prev: string, cur: string) => prev + ` > ${cur}`
          )}
        </WaffleText>
      </React.Fragment>
    )
  );
};
