import Config from 'react-native-config';
import { Platform } from 'react-native';

export default () => {
  if (Platform.OS === 'web') {
    return {
      API_URL: `${process.env.V2_API_URL}/ladder`,
    };
  } else {
    return {
      API_URL: `${Config.V2_API_URL}/ladder`,
    };
  }
};
