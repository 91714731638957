export const Coverages = [
  100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000,
  550000, 600000, 650000, 700000, 750000, 800000, 850000, 900000, 950000,
  1000000, 1100000, 1200000, 1300000, 1400000, 1500000, 1600000, 1700000,
  1800000, 1900000, 2000000, 2250000, 2500000, 2750000, 3000000, 3250000,
  3500000, 3750000, 4000000, 4250000, 4500000, 4750000, 5000000, 5500000,
  6000000, 6500000, 7000000, 7500000, 8000000,
];

export const Terms = [10, 15, 20, 25, 30];

export const TobaccoValues = [
  'never',
  'within_last_12_months',
  '12_to_23_months',
  '24_to_35_months',
  '36_months_or_greater',
];

export const GenderValues = ['male', 'female'];

export const APPLICATION_ID_LOCAL_STORAGE_KEY = 'applicationId';
