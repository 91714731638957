import React from 'react';
import styled from 'styled-components';
import WaffleText from '../../Components/WaffleText';
import { WaffleOrange } from '../../Constants/Style';
import LoadingRectangle from '../../Components/LoadingRectangle';

const QuoteContainer = styled.button`
  border: 1px solid #e9ddd5;
  width: 164px;
  height: 164px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

type Props = {
  term: number;
  premium: string;
  coverage: string;
  onClick: () => void;
};

export const LoadingQuoteOption = () => (
  <QuoteContainer>
    <LoadingRectangle width={120} height={25} />
    <LoadingRectangle width={60} height={10} />
    <LoadingRectangle width={60} height={10} />
  </QuoteContainer>
);

export default function QuoteOption({
  term,
  premium,
  onClick,
}: Props): React.ReactElement {
  return (
    <QuoteContainer onClick={onClick}>
      <WaffleText style={{ fontSize: 30, color: WaffleOrange }}>
        {premium}
      </WaffleText>
      <WaffleText style={{ fontSize: 14, color: WaffleOrange }}>
        {'per month'}
      </WaffleText>
      <WaffleText style={{ fontSize: 18 }}>{term + ' years'}</WaffleText>
    </QuoteContainer>
  );
}
