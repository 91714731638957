import React from 'react';
import ReactSelect, { GroupBase, Props } from 'react-select';
import { defaultStylesConfig } from './react-select-default.constants';

export default function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>): React.ReactElement {
  return <ReactSelect styles={defaultStylesConfig} {...props} />;
}
