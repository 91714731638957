import { AnswerOption } from '../models/application';

export const getHeightAnswerOptions = (
  minFeet = 4,
  firstInch = 10,
  maxFeet = 8,
  lastInch = 11
): Array<AnswerOption> => {
  if (minFeet <= 0 || maxFeet <= 0) {
    throw new Error('Feet are out of bounds!');
  }

  if (firstInch < 0 || lastInch < 0 || firstInch >= 12 || lastInch >= 12) {
    throw new Error('Inches are out of bounds!');
  }
  const heightAnswerOptions = new Array<AnswerOption>();

  let minInch = firstInch,
    maxInch = 11;

  for (let feet = minFeet; feet <= maxFeet; feet += 1) {
    if (feet === maxFeet) {
      maxInch = lastInch;
    }
    for (let inches = minInch; inches <= maxInch; inches += 1) {
      heightAnswerOptions.push({
        value: feet * 12 + inches,
        text: `${feet}'${inches}"`,
      });
    }
    minInch = 0;
  }
  return heightAnswerOptions;
};

export const getGenderAnswerOptions = (): Array<AnswerOption> => {
  const genderAnswerOptions = new Array<AnswerOption>();
  genderAnswerOptions.push(
    { value: 'male', text: 'Male' } as AnswerOption,
    { value: 'female', text: 'Female' } as AnswerOption
  );
  return genderAnswerOptions;
};

export const getTobaccoUseAnswerOptions = (): Array<AnswerOption> => {
  const tobaccoUseAnswerOptions = new Array<AnswerOption>();

  tobaccoUseAnswerOptions.push(
    {
      value: 'never',
      text: 'Never used',
    } as AnswerOption,
    {
      value: 'within_last_12_months',
      text: 'Within last 12 months',
    } as AnswerOption,
    { value: '12_to_23_months', text: '12-23 months' } as AnswerOption,
    { value: '24_to_35_months', text: '24-35 months' } as AnswerOption,
    {
      value: '36_months_or_greater',
      text: '36 months or greater',
    } as AnswerOption
  );

  return tobaccoUseAnswerOptions;
};
