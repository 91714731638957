import {
  ApplicationError,
  paymentDetailsKey,
  GenericHttpErr,
  HttpOfferErr,
  queryDetailsKey,
} from '../api-error.constants';

// Internal
const dynamicAppErrMsg = (
  err: ApplicationError,
  detailsKey: string
): string => {
  const hasErrDetails = err.details && err.details[detailsKey];
  let errDetails: string;

  if (hasErrDetails) {
    errDetails = err.details[detailsKey].replace('_', ' ');
    errDetails = errDetails.charAt(0).toUpperCase() + errDetails.substring(1);

    return `${appErrMsg(err)} ${errDetails}`;
  }

  return appErrMsg(err);
};
// Public
export const appErrMsg = (err: ApplicationError): string => {
  if (!err || !err.type) {
    return 'Unknown error';
  }

  let readableErrType = err.type.replace('_', ' ');
  readableErrType =
    readableErrType.charAt(0).toUpperCase() + readableErrType.substring(1);

  return err.message
    ? `${readableErrType}: ${err.message}`
    : `${readableErrType}.`;
};

export const autocompleteAppErrMsg = (err: ApplicationError): string =>
  dynamicAppErrMsg(err, queryDetailsKey);

export const paymentAppErrMsg = (err: ApplicationError): string =>
  dynamicAppErrMsg(err, paymentDetailsKey);

//TODO: Move this utility function out of here? Doesn't really have much to do with the api-error messages
/** If no error message is detected, a generic unknown error message is provided. Mainly used for testing purposes. */
export const errMsg = (errorMessage: string): string =>
  errorMessage ? errorMessage : 'Unknown error occurred';

/**
 * See error code definitions here: https://ladderlife.github.io/api/#errors
 * @param err
 * @returns error message
 */
export const httpErrMsg = (httpErrCode: number | null | undefined): string => {
  switch (httpErrCode) {
    case 400:
      return GenericHttpErr.Status400;
    case 401:
      return GenericHttpErr.Status401;
    case 403:
      return GenericHttpErr.Status403;
    case 404:
      return GenericHttpErr.Status404;
    case 429:
      return GenericHttpErr.Status429;
    case 500:
      return GenericHttpErr.Status500;
    case 503:
      return GenericHttpErr.Status503;
    default:
      return GenericHttpErr.StatusUnknown;
  }
};

export const httpOfferErrMsg = (
  httpErrCode: number | null | undefined
): string => {
  switch (httpErrCode) {
    case 410:
      return HttpOfferErr.Status410;
    case 409:
      return HttpOfferErr.Status409;
    case 404:
      return HttpOfferErr.Status404;
    case 402:
      return HttpOfferErr.Status402;
    case 400:
      return HttpOfferErr.Status400;
    default:
      return httpErrMsg(httpErrCode);
  }
};
