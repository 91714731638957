import React from 'react';
import {
  ViewStyle,
  StyleSheet,
  TouchableWithoutFeedbackProps,
} from 'react-native';
import styled from 'styled-components/native';

export type ArrowDirection = 'up' | 'down' | 'left' | 'right';
type Props = {
  children?: React.ReactNode;
  buttonStyle?: ViewStyle;
  arrowHeadStyle?: ViewStyle;
  arrowTailStyle?: ViewStyle;
  direction?: ArrowDirection;
};

const RightArrowButtonContainer = styled.TouchableOpacity`
  background: #f98d46;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
`;

const LeftArrowButtonContainer = styled.TouchableOpacity`
  background: white;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
`;

const ArrowImage = styled.Image`
  width: 50px;
  height: 50px;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const neverReached = (_never: never) => {};

type withTouchableProps = Props & TouchableWithoutFeedbackProps;
export const ArrowButton = (props: withTouchableProps): React.ReactElement => {
  const {
    direction = 'right',
    accessibilityRole,
    disabled = false,
    style: touchableStyle,
    ...touchableProps
  } = props;

  if (direction === 'right') {
    return (
      <RightArrowButtonContainer
        disabled={disabled}
        style={[disabled ? styles.disabled : styles.active, touchableStyle]}
        accessibilityRole={accessibilityRole ? accessibilityRole : 'button'}
        {...touchableProps}>
        <ArrowImage
          source={require('../../../assets/images/arrow-right.png')}
        />
      </RightArrowButtonContainer>
    );
  }
  return (
    <LeftArrowButtonContainer
      disabled={disabled}
      style={[disabled ? styles.disabled : styles.active, touchableStyle]}
      accessibilityRole={accessibilityRole ? accessibilityRole : 'button'}
      {...touchableProps}>
      <ArrowImage source={require('../../../assets/images/arrow-left.png')} />
    </LeftArrowButtonContainer>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    height: 63,
    width: 63,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: '#F98D46',
    backgroundColor: '#F98D46',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowHeadStyle: {
    borderStyle: 'solid',
    borderColor: 'white',
    borderTopWidth: 0,
    borderRightWidth: 6,
    borderBottomWidth: 6,
    borderLeftWidth: 0,
    padding: 18,
  },
  arrowTailStyle: {
    borderStyle: 'solid',
    borderColor: 'white',
    borderTopWidth: 0,
    borderRightWidth: 53,
    borderBottomWidth: 5,
    borderLeftWidth: 0,
    position: 'absolute',
  },
  disabled: {
    opacity: 0.3,
  },
  active: {
    opacity: 1,
  },
});
