import React, { FunctionComponent } from 'react';
import styled from 'styled-components/native';

import WaffleText from '../Components/WaffleText';
import Spinner from '../Components/Spinner';
import { Logger } from '../Helper/Logger';
import { PlainView } from '../Components/SimpleComponents';
import { LogoLargeImage } from '../Constants/Style';

const Text = styled(WaffleText)`
  font-size: 26px;
  line-height: 30px;
  margin-top: 13px;
  margin-bottom: 13px;
  text-align: center;
`;

const LoadingView = styled.View`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.Image`
  width: 219px;
  height: 54px;
  margin-bottom: 36px;
`;

//
// put random messages
//   adjusting flux capacitors
// todo:
// - handle errors more gracefully
// - for errors, add a button to allow a user to go back to Home?
//
interface Props {
  errors?: string;
  spinner?: boolean;
}

// todo : deprecate!
const LoadingScreen: FunctionComponent<Props> = ({
  errors,
  spinner = true,
}) => {
  if (errors) {
    Logger(`ERROR LoadingScreen: ${JSON.stringify(errors)}`);
  }

  return (
    <LoadingView>
      <LogoImage source={LogoLargeImage} />

      {errors && <Text>{errors}</Text>}

      {!errors && (
        <PlainView>
          <Text>Loading Amazing!</Text>
          {spinner && <Spinner />}
        </PlainView>
      )}
    </LoadingView>
  );
};

export default LoadingScreen;
