import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { Spacer } from '../../Components/SimpleComponents';
import { StackScreenProps } from '@react-navigation/stack';
import {
  LifeApplicationProcessingScreenName,
  RootStackParamList,
} from '../../../screen-config';
import { getLifeRedirectLink } from '../LifeQuoteScreen/models/application';
import {
  getLifePolicyMetaDataAsync,
  useLifeInsurance,
} from '../../../lib/life';
import LifeProcessingView from '../LifeApplicationReviewScreen/LifeProcessingView';
import { useLadderLogoNavigationHeader } from '../LifeApplicationReviewScreen/useLadderNavigationHeader';
import { useApolloClient } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';
import Popup from '../../Components/Popup';
import WaffleText from '../../Components/WaffleText';
import { WaffleOrange } from '../../Constants/Style';
import { FAB } from 'react-native-elements';
import { useAuthContext } from '../AuthProvider';
import LifeContainer from '../../Helper/LifeContainer';

async function wait(seconds: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

export default function LifeProcessingScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'Life Redirect'>): React.ReactElement {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { redirectReason } = useLifeInsurance();
  const { isGuest } = useAuthContext();
  const apolloClient = useApolloClient();

  const prettyRedirectReason = redirectReason
    ? redirectReason.replace(/_/g, ' ')
    : 'unknown';

  useLadderLogoNavigationHeader(
    navigation,
    LifeApplicationProcessingScreenName,
    apolloClient,
    isGuest
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        const lifePolicyMetaData = await getLifePolicyMetaDataAsync(
          apolloClient
        );

        const redirectLink = await getLifeRedirectLink(
          lifePolicyMetaData.ExternalUserId
        );
        await wait(3);
        window.location.href = redirectLink.link;
      })();
    }, [])
  );

  const redirectMessage =
    'You’re being redirected to our partner Ladder to finish the process.';

  return (
    <LifeContainer>
      <Spacer y={4} />
      <LifeProcessingView
        title="Almost there!"
        // Removing the why b/c of an ask from Ladder. We can still use this to help debug. message={`${redirectMessage} Why: ${prettyRedirectReason}`}
        message={`${redirectMessage}`}
      />
      <Spacer y={6} />
      <FAB
        color={WaffleOrange}
        onPress={() => setModalIsOpen(true)}
        visible={true}
        placement="right"
        icon={{
          name: 'question',
          color: 'white',
          type: 'material-community',
        }}
      />
      <Popup
        hasSideMenu={false}
        visible={modalIsOpen}
        onDismiss={() => setModalIsOpen(false)}>
        <Spacer y={2} />
        <WaffleText style={{ textAlign: 'center', fontSize: 18 }}>
          {'Have a question or running into any issues? Email us at '}
          <a
            style={{
              color: WaffleOrange,
            }}
            href="mailto:contact@waffle-labs.com?subject=Waffle%20Life%20Insurance%20Support"
            target="_blank"
            rel="noreferrer">
            {'contact@waffle-labs.com'}
          </a>
        </WaffleText>
      </Popup>
    </LifeContainer>
  );
}
