import { format, isMatch, parse, parseISO } from 'date-fns';

export const displayFmt = 'MM/dd/yyyy';
export const isoFmt = 'yyyy-MM-dd';

/**
 * Checks the given input value against the given date format
 * @param date
 * @param fmt
 * @returns whether valid or not valid
 */
export const isValidFmt = (date: string, fmt: string): boolean =>
  date?.length === fmt.length && isMatch(date, fmt);
// Do we need to check length? Length probably won't work in every scenario but we only have two formats
// TODO: Investigate if some option or format allows us to avoid checking against length ourselves

/**
 * Attempts to format the ISO 8601 format yyyy-MM-dd to the display date format MM/dd/yyyy
 * @param isoDate
 * @returns the original value or the formatted value
 */
export const fmtISOToDisplayDt = (isoDate: string): string => {
  if (!isValidFmt(isoDate, isoFmt)) {
    return isoDate;
  }

  const parsedISODt = parseISO(isoDate);

  return format(parsedISODt, displayFmt);
};

/**
 * Attempts to format the display date MM/dd/yyyy to ISO 8601 format yyyy-MM-dd
 * @param displayDate
 * @returns the original value or the formatted value
 */
export const fmtDisplayDtToISO = (displayDate: string): string => {
  if (!isValidFmt(displayDate, displayFmt)) {
    return displayDate;
  }

  const parsedDt = parse(displayDate, displayFmt, new Date());

  return format(parsedDt, isoFmt); // formatISO fn also exists, not sure if it makes a difference?
};

/**
 * Length without the forward slashes
 */
const maxDisplayDtLen = displayFmt.length - 2;
/**
 * Trys to format input to MM/dd/yyyy
 * @param displayDate
 * @returns formatted output
 */
export const fmtDisplayDtInput = (displayDate: string): string => {
  if (!displayDate) {
    return '';
  }

  let prunedDate = displayDate.replace(/[^0-9]/g, '');

  if (prunedDate.length > maxDisplayDtLen) {
    prunedDate = prunedDate.substring(0, maxDisplayDtLen);
  }

  if (!prunedDate) {
    return '';
  }

  let formattedDt = `${prunedDate.substring(0, 2)}`;

  if (prunedDate.length >= 3) {
    formattedDt += `/${prunedDate.substring(2, 4)}`;
  }

  if (prunedDate.length >= 5) {
    formattedDt += `/${prunedDate.substring(4, maxDisplayDtLen)}`;
  }

  return formattedDt;
};

export const onDateChange = (userDateInput: string): string => {
  return fmtDisplayDtToISO(fmtDisplayDtInput(userDateInput));
};
