import React, { useMemo, useState } from 'react';
import { useAuthContext } from '../AuthProvider';
import { Gender } from '../LifeQuoteScreen/models/application';
import { formatZip } from './utils/formatters';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import { validationErr400 } from './api-error.constants';
import Sentry from '../../Sentry';
import { errMsg, httpErrMsg } from './utils/api-error-message';
import { Spacer } from '../../Components/SimpleComponents';
import WaffleText from '../../Components/WaffleText';
import WaffleTextInput from '../../Components/WaffleTextInput';
import Select from './Select';
import {
  fmtISOToDisplayDt,
  onDateChange,
} from '../LifeQuoteScreen/utils/date-format-helper';
import { formatMoney } from '../LifeQuoteScreen/utils/display-hint-formatter-factory';
import { GenderValues } from './constants';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { ErrorContainer } from '../../Components/ErrorContainer';
import styled from 'styled-components';
import { getHeightAnswerOptions } from '../LifeQuoteScreen/utils/generate-quote-answer-options';
import { LifeQuestionAnswersPart1 } from './LifeQuestionAnswersPart1';
import validator from 'validator';
import { ErrorFooterText } from '../../Components/WaffleHalfTextInput';
import differenceInYears from 'date-fns/differenceInYears';

type Props = {
  onSubmit: (quoteRequest: LifeQuestionAnswersPart1) => Promise<void>;
  firstName: string;
  lastName: string;
  email: string;
  gender: Gender;
  dateOfBirth: string;
  height: number;
  weight: string;
  annualIncome: string;
  zipCode: string;
};

const isDateValid = (dob: string) => {
  if (!dob) return false;
  const isProperlyFormatted = validator.isDate(fmtISOToDisplayDt(dob), {
    format: 'MM/DD/YYYY',
    strictMode: true,
  });

  const age = differenceInYears(new Date(), new Date(dob));
  return isProperlyFormatted && age >= 18;
};

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Card = styled.div`
  box-shadow: 0px 4px 28px -9px rgba(0, 0, 0, 0.25);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
  max-width: 375px;
  width: 85%;
  padding: 16px;
`;

const genderOptions = GenderValues.map((gender) => ({
  label: gender.charAt(0).toUpperCase() + gender.substring(1, gender.length),
  value: gender,
}));

const heightOptions = getHeightAnswerOptions().map((v) => ({
  label: v.text,
  value: v.value as number,
}));

export default function LifeQuoteQuestionsPart1View({
  onSubmit,
  ...props
}: Props): React.ReactElement {
  const [firstName, setFirstName] = useState({
    value: props.firstName ?? '',
    isValid: false,
    errorMessage: '',
  });
  const [lastName, setLastName] = useState({
    value: props.lastName ?? '',
    isValid: false,
    errorMessage: '',
  });
  const [email, setEmail] = useState({
    value: props.email ?? '',
    isValid: false,
    errorMessage: '',
  });
  const [gender, setGender] = useState<{
    value: Gender;
    isValid: boolean;
    errorMessage: string;
  }>({
    value: props.gender,
    isValid: false,
    errorMessage: '',
  });
  const [dateOfBirth, setDateOfBirth] = useState({
    value: props.dateOfBirth,
    isValid: false,
    errorMessage: '',
  });
  const [height, setHeight] = useState({
    value: props.height,
    isValid: false,
    errorMessage: '',
  });

  const [weight, setWeight] = useState({
    value: props.weight,
    isValid: false,
    errorMessage: '',
  });

  const [annualIncome, setAnnualIncome] = useState({
    value: props.annualIncome,
    isValid: false,
    errorMessage: '',
  });

  const [zipCode, setZipcode] = useState({
    value: props.zipCode,
    isValid: false,
    errorMessage: '',
  });

  const [error, setError] = useState<string>('');

  async function _onSubmit() {
    if (error) {
      setError('');
    }

    // const externalUserId = await createExternalUserIdAsync(authContext.user.id);
    try {
      const firstNameIsValid = !validator.isEmpty(firstName.value);
      setFirstName({
        ...firstName,
        isValid: firstNameIsValid,
        errorMessage: firstNameIsValid ? '' : 'First name is a required',
      });

      const lastNameIsValid = !validator.isEmpty(lastName.value);
      setLastName({
        ...lastName,
        isValid: lastNameIsValid,
        errorMessage: lastNameIsValid ? '' : 'Last name is a required',
      });

      const genderIsValid = validator.isIn(gender.value ?? '', GenderValues);
      setGender({
        ...gender,
        isValid: genderIsValid,
        errorMessage: genderIsValid ? '' : 'Gender is required',
      });

      const emailIsValid = validator.isEmail(email.value ?? '');
      setEmail({
        ...email,
        isValid: emailIsValid,
        errorMessage: emailIsValid ? '' : 'Invalid email',
      });

      const dobIsValid = isDateValid(dateOfBirth.value ?? '');
      setDateOfBirth({
        ...dateOfBirth,
        isValid: dobIsValid,
        errorMessage: dobIsValid
          ? ''
          : 'Date of birth needs to be in the format of MM/DD/YYYY and must be at least 18 years old',
      });

      const heightIsValid = validator.isInt(height.value?.toString() ?? '', {
        min: 58,
        max: 108,
      });
      setHeight({
        ...height,
        isValid: heightIsValid,
        errorMessage: heightIsValid
          ? ''
          : 'Minimum height is 4 ft 10 in and max is 8 ft 11 in',
      });

      const weightIsValid = validator.isInt(weight.value ?? '', {
        max: 599,
      });

      setWeight({
        ...weight,
        isValid: weightIsValid,
        errorMessage: weightIsValid ? '' : 'Weight must be less than 600 lbs',
      });

      const annualIncomeIsValid = validator.isInt(annualIncome.value ?? '', {
        min: 0,
        max: 999999999,
      });
      setAnnualIncome({
        ...annualIncome,
        isValid: annualIncomeIsValid,
        errorMessage: annualIncomeIsValid
          ? ''
          : 'Annual income is required and must be less than $1,000,000,000',
      });

      const zipCodeIsValid = validator.isPostalCode(zipCode.value ?? '', 'US');
      setZipcode({
        ...zipCode,
        isValid: zipCodeIsValid,
        errorMessage: zipCodeIsValid ? '' : 'Must be a valid US zipcode',
      });

      if (
        firstNameIsValid &&
        lastNameIsValid &&
        emailIsValid &&
        genderIsValid &&
        annualIncomeIsValid &&
        heightIsValid &&
        weightIsValid &&
        dobIsValid &&
        zipCodeIsValid
      ) {
        await onSubmit({
          isValid: true,
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          gender: gender.value,
          dateOfBirth: dateOfBirth.value,
          height: height.value,
          weight: weight.value,
          annualIncome: annualIncome.value,
          zipCode: zipCode.value,
        });
      }
    } catch (e) {
      if (e.response?.status !== validationErr400) {
        // Mainly care about capturing non-validation type errors
        Sentry.captureException(e);
      }
      setError(e.response ? httpErrMsg(e.response.status) : errMsg(e.message));
    }
  }

  return (
    <Card>
      <Spacer y={2} />
      <WaffleText style={{ fontSize: 30, textAlign: 'center' }}>
        Part 1: All About You!
      </WaffleText>
      <WaffleTextInput
        containerStyle={{
          width: '100%',
        }}
        value={firstName.value}
        onChangeText={(t) =>
          setFirstName({
            ...firstName,
            value: t,
          })
        }
        placeholder="First Name"
        optional={false}
        errorFooterText={firstName.errorMessage}
      />
      <WaffleTextInput
        containerStyle={{
          width: '100%',
        }}
        value={lastName.value}
        onChangeText={(t) =>
          setLastName({
            ...lastName,
            value: t,
          })
        }
        placeholder="Last Name"
        optional={false}
        errorFooterText={lastName.errorMessage}
      />
      <Spacer y={2.5} />
      <SelectorContainer>
        <Select
          value={genderOptions.find((v) => v.value === gender.value)}
          onChange={(v) => {
            if (v.value === 'male') {
              setGender({
                ...gender,
                value: v.value,
              });
            } else if (v.value === 'female') {
              setGender({
                ...gender,
                value: v.value,
              });
            } else if (v.value === 'other') {
              setGender({
                ...gender,
                value: v.value,
              });
            }
          }}
          placeholder="Gender"
          options={genderOptions}
        />
        {gender.errorMessage && (
          <ErrorFooterText>{gender.errorMessage}</ErrorFooterText>
        )}
      </SelectorContainer>
      <WaffleTextInput
        value={email.value}
        onChangeText={(t) =>
          setEmail({
            ...email,
            value: t,
          })
        }
        errorFooterText={email.errorMessage}
        placeholder="Email"
        containerStyle={{
          width: '100%',
        }}
        otherProps={{
          keyboardType: 'email-address',
          autoCompleteType: 'off',
          textContentType: 'none',
          editable: true,
          autoCapitalize: 'none',
        }}
        optional={false}
      />
      <WaffleTextInput
        value={useMemo(
          () => fmtISOToDisplayDt(dateOfBirth.value),
          [dateOfBirth]
        )}
        placeholder={'Date of Birth (MM/DD/YYYY)'}
        onChangeText={(dateValue: string) => {
          const newValue = onDateChange(dateValue);
          setDateOfBirth({
            ...dateOfBirth,
            value: newValue,
          });
        }}
        errorFooterText={dateOfBirth.errorMessage}
        containerStyle={{
          width: '100%',
        }}
        optional={false}
      />
      <Spacer y={2.5} />
      <SelectorContainer>
        <Select
          value={heightOptions.find((v) => v.value === height.value)}
          onChange={(v) => {
            setHeight({
              ...height,
              value: v.value,
            });
          }}
          placeholder="Height"
          options={heightOptions}
        />
        {height.errorMessage && (
          <ErrorFooterText>{height.errorMessage}</ErrorFooterText>
        )}
      </SelectorContainer>

      <WaffleTextInput
        value={weight.value}
        containerStyle={{
          width: '100%',
        }}
        placeholder="Weight (lbs)"
        onChangeText={(text: string) => {
          const newValue = text.replace(/[^0-9]/g, '');
          setWeight({
            ...weight,
            value: newValue,
          });
        }}
        errorFooterText={weight.errorMessage}
      />
      <WaffleTextInput
        containerStyle={{
          width: '100%',
        }}
        value={formatMoney(annualIncome.value)}
        placeholder="Annual Income"
        onChangeText={(text: string) => {
          const newValue = text.replace(/[^0-9]/g, '');
          setAnnualIncome({
            ...annualIncome,
            value: newValue,
          });
        }}
        errorFooterText={annualIncome.errorMessage}
      />
      <WaffleTextInput
        containerStyle={{
          width: '100%',
        }}
        value={zipCode.value}
        placeholder="Zipcode"
        onChangeText={(text: string) => {
          const newValue = formatZip(text);
          setZipcode({
            ...zipCode,
            value: newValue,
          });
        }}
        errorFooterText={zipCode.errorMessage}
        otherProps={{ keyboardType: 'number-pad' }}
      />
      <Spacer y={4} />
      <WaffleButtonAsync
        style={{
          borderRadius: 13,
          maxWidth: 146,
        }}
        onPress={_onSubmit}
        name="Next"
      />
      <Spacer y={2} />

      {error && <ErrorContainer> {error} </ErrorContainer>}
    </Card>
  );
}
