export type ApplicationError = {
  details?: object;
  message?: string;
  type: string;
};

/** Key used to identify an autocomplete query error in the details object. */
export const queryDetailsKey = 'query';

/** Key used to identify a payment submission error in the details object from the Offer v2 API */
export const paymentDetailsKey = 'code';

/** Ladder life commonly uses status 400 to indicate a client-side validation error occurred. */
export const validationErr400 = 400;

/** According to the ladder life docs, all endpoints can return these error status codes.
 * See: https://ladderlife.github.io/api/application#errors
 */
export class GenericHttpErr {
  static get Status400(): string {
    return 'Invalid input, please check your input values and try again';
  }

  static get Status401(): string {
    return 'Missing or invalid API key. Try reloading the page if the issue persists';
  }

  static get Status403(): string {
    return 'Account does not have permission to access endpoint';
  }

  static get Status404(): string {
    return 'Endpoint does not exist';
  }

  static get Status429(): string {
    return "You've issued more requests than what Ladder allows in a period of time";
  }

  static get Status500(): string {
    return 'Ladder server problem. Please try again later';
  }

  static get Status503(): string {
    return 'Service unavailable. Temporarily offline for maintenance. Please try again later';
  }

  static get StatusUnknown(): string {
    return 'Unknown error occurred, please try again';
  }
}

/** See: https://ladderlife.github.io/api/application#offers */
export class HttpOfferErr {
  static get Status410(): string {
    return 'The offer expired';
  }

  static get Status409(): string {
    return 'The offer is inactive and cannot be accepted or refused';
  }

  static get Status404(): string {
    return 'Invalid offer ID';
  }

  static get Status402(): string {
    return 'No payment method was set';
  }

  static get Status400(): string {
    return 'Payment failed while attempting to charge user';
  }
}
