import React from 'react';
import { View, StyleSheet, Pressable, PressableProps } from 'react-native';
import { Spacer } from '../../Components/SimpleComponents';
import { AnswerOption, AnswerValueScalar } from './models/application';
import styledNative from 'styled-components/native';
import styled from 'styled-components';
import WaffleText from '../../Components/WaffleText';

type Props = {
  selected: AnswerValueScalar[];
  answerOptions: AnswerOption[];
  onValueChange: (value: AnswerValueScalar[]) => void;
  optionTestIDPrefix?: string;
  radioGroupTestID?: string;
};
const RadioGroupContainer = styledNative.View`
  width: 100%;
  height: auto;
  align-items: center;
`;

const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 327px;
  height: 100%;
`;

const RadioBtnImage = styledNative.Image`
  width: 22px;
  height: 22px;
  margin-left: 20px;
  margin-right: 20px;
`;

const CheckmarkImage = styledNative.Image`
  width: 26px;
  height: 19px;
  position: absolute;
  margin-bottom: 5px;
  margin-left: 22px;
`;
const DeselectedRadio = (): React.ReactElement => (
  <RadioBtnImage
    source={require('../../../assets/images/products/life/deselected_multi_radio_rect.png')}
  />
);

const SelectedRadio = (): React.ReactElement => {
  return (
    <React.Fragment>
      <RadioBtnImage
        source={require('../../../assets/images/products/life/selected_multi_radio_rect.png')}
      />
      <CheckmarkImage
        source={require('../../../assets/images/products/life/orange_checkmark.png')}
      />
    </React.Fragment>
  );
};

type PressableRadioProps = {
  selected: boolean;
};

type withPressableProps = PressableProps &
  PressableRadioProps &
  React.RefAttributes<View>;

const PressableRadio = (props: withPressableProps): React.ReactElement => {
  const { selected, children, ...pressableProps } = props;
  return (
    <Pressable accessibilityRole={'radio'} {...pressableProps}>
      {selected ? <SelectedRadio /> : <DeselectedRadio />}
      {children}
    </Pressable>
  );
};

export const PressableRadioGroupMulti = (props: Props): React.ReactElement => {
  const {
    selected,
    onValueChange,
    optionTestIDPrefix = 'pressable-radio-',
    radioGroupTestID = 'pressable-radio-group',
    answerOptions,
  } = props;

  if (!answerOptions || answerOptions.length === 0) {
    throw new Error('AnswerOptions is null/undefined or empty');
  }

  const selectedSet = new Set<AnswerValueScalar>(selected); // keep it immutable

  return (
    <RadioGroupContainer accessibilityRole="radiogroup">
      {answerOptions.map(
        (option: AnswerOption, i: number, answerOpts: AnswerOption[]) => (
          <React.Fragment key={`fragment_${i}`}>
            <RadioContainer key={`view_${i}`}>
              <PressableRadio
                key={`radio_${i}`}
                style={styles.pressableStyle}
                selected={selectedSet.has(option.value)}
                onPress={() => {
                  const isSelected = selectedSet.has(option.value);

                  if (isSelected) {
                    selectedSet.delete(option.value);
                  } else {
                    selectedSet.add(option.value);
                  }
                  onValueChange(Array.from(selectedSet));
                }}>
                <WaffleText key={`text_${i}`} style={styles.textStyle}>
                  {option.text}
                </WaffleText>
              </PressableRadio>
            </RadioContainer>
            {answerOpts.length - 1 !== i && (
              <Spacer key={`spacer_${i}`} y={2} />
            )}
          </React.Fragment>
        )
      )}
    </RadioGroupContainer>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    color: '#72777A',
    lineHeight: 16,
    fontSize: 16,
    minWidth: 0, // Specify min-width so flex wrap works properly on long words or words with no spaces
  },
  pressableStyle: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    borderColor: '#CDCFD0',
    borderWidth: 3,
    borderHeight: 'auto',
    borderStyle: 'solid',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
  },
});
