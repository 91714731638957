import React, { useState } from 'react';
import { useAuthContext } from '../AuthProvider';
import { TobaccoUse } from '../LifeQuoteScreen/models/application';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';
import { validationErr400 } from './api-error.constants';
import Sentry from '../../Sentry';
import { errMsg, httpErrMsg } from './utils/api-error-message';
import { Spacer } from '../../Components/SimpleComponents';
import WaffleText from '../../Components/WaffleText';
import Select from './Select';
import Toggle from '../../Components/Toggle';
import { TobaccoValues } from './constants';
import { WaffleButtonAsync } from '../../Components/WaffleButtonAsync';
import { ErrorContainer } from '../../Components/ErrorContainer';
import styled from 'styled-components';
import { LifeQuestionAnswersPart2 } from './LifeQuestionAnswersPart2';
import { WaffleOrange } from '../../Constants/Style';
import { valueExists } from './utils/validators.';
import { ErrorFooterText } from '../../Components/WaffleHalfTextInput';
import validator from 'validator';

type Props = {
  onSubmit: (quoteRequest: LifeQuestionAnswersPart2) => Promise<void>;
  onBack: () => Promise<void>;
  hasBiologicalMedicalConditions: boolean;
  tobacco: TobaccoUse;
};

const Card = styled.div`
  box-shadow: 0px 4px 28px -9px rgba(0, 0, 0, 0.25);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
  max-width: 375px;
  width: 85%;
  padding: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const MedicalContainer = styled.div`
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  display: flex;
  align-items: center;
`;

const TobaccoOptions = TobaccoValues.map((t) => ({
  label: t.replace(/_/g, ' '),
  value: t,
}));

export default function LifeQuoteQuestionsPart2View({
  onSubmit,
  onBack,
  ...props
}: Props): React.ReactElement {
  const [tobacco, setTobacco] = useState<{
    value: TobaccoUse;
    isValid: boolean;
    errorMessage: string;
  }>({
    value: props.tobacco,
    isValid: valueExists(props.tobacco),
    errorMessage: '',
  });
  const [hasBiologicalMedicalConditions, setHasBiologicalMedicalConditions] =
    useState<boolean>(props.hasBiologicalMedicalConditions);
  const [error, setError] = useState<string>('');

  const tracker = useUserTracking();
  const authContext = useAuthContext();

  async function _onSubmit() {
    if (error) {
      setError('');
    }

    try {
      const tobaccoIsValid = !validator.isEmpty(tobacco.value);
      setTobacco({
        ...tobacco,
        isValid: tobaccoIsValid,
        errorMessage: tobaccoIsValid
          ? ''
          : 'Last time tobacco was used is required',
      });

      if (tobaccoIsValid) {
        await onSubmit({
          tobacco: tobacco.value,
          hasBiologicalMedicalConditions,
          isValid: true,
        });
      }
    } catch (e) {
      if (e.response?.status !== validationErr400) {
        // Mainly care about capturing non-validation type errors
        Sentry.captureException(e);
      }
      setError(e.response ? httpErrMsg(e.response.status) : errMsg(e.message));
    }
  }

  return (
    <Card>
      <Spacer y={2} />
      <WaffleText style={{ fontSize: 30, textAlign: 'center' }}>
        Part 2: Medical
      </WaffleText>
      <Spacer y={2} />
      <MedicalContainer>
        <WaffleText style={{ fontSize: 16, textAlign: 'center' }}>
          {
            'I have a biological parent or sibling that has been diagnosed with diabetes, cancer or heart disease prior to the age of 60 by a medical professional'
          }
        </WaffleText>
        <Spacer y={2} />
        <Toggle
          onPress={() =>
            setHasBiologicalMedicalConditions(!hasBiologicalMedicalConditions)
          }
          isEnabled={hasBiologicalMedicalConditions}
        />
      </MedicalContainer>

      <Spacer y={2} />

      <MedicalContainer>
        <WaffleText style={{ fontSize: 18, textAlign: 'center' }}>
          {'When was the last time you used tobacco or a nicotine product?'}
        </WaffleText>
        <Spacer y={2} />
        <Select
          value={TobaccoOptions.find((t) => t.value === tobacco.value)}
          options={TobaccoOptions}
          onChange={(v) => {
            if (v.value === 'never') {
              setTobacco({
                ...tobacco,
                value: v.value,
                isValid: valueExists(v.value),
              });
            } else if (v.value === 'within_last_12_months') {
              setTobacco({
                ...tobacco,
                value: v.value,
                isValid: valueExists(v.value),
              });
            } else if (v.value === '12_to_23_months') {
              setTobacco({
                ...tobacco,
                value: v.value,
                isValid: valueExists(v.value),
              });
            } else if (v.value === '24_to_35_months') {
              setTobacco({
                ...tobacco,
                value: v.value,
                isValid: valueExists(v.value),
              });
            } else if (v.value === '36_months_or_greater') {
              setTobacco({
                ...tobacco,
                value: v.value,
                isValid: valueExists(v.value),
              });
            }
          }}
        />
        {tobacco.errorMessage && (
          <ErrorFooterText>{tobacco.errorMessage}</ErrorFooterText>
        )}
      </MedicalContainer>

      <Spacer y={4} />
      <ButtonContainer>
        <WaffleButtonAsync
          style={{
            borderRadius: 13,
            borderWidth: 1,
            borderColor: WaffleOrange,
            maxWidth: 146,
            background: 'white',
          }}
          textStyle={{
            color: WaffleOrange,
          }}
          onPress={onBack}
          name="Back"
        />
        <Spacer x={2} />
        <WaffleButtonAsync
          disabled={!tobacco.isValid}
          style={{
            borderRadius: 13,
            maxWidth: 146,
          }}
          onPress={_onSubmit}
          name="Next"
        />
      </ButtonContainer>

      <Spacer y={2} />

      {error && <ErrorContainer> {error} </ErrorContainer>}
    </Card>
  );
}
