import React from 'react';
import ReactSelectAsync, { AsyncProps } from 'react-select/async';
import { GroupBase } from 'react-select/';
import { defaultStylesConfig } from './react-select-default.constants';

export default function SelectAsync<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: AsyncProps<Option, IsMulti, Group>): React.ReactElement {
  return <ReactSelectAsync styles={defaultStylesConfig} {...props} />;
}
